:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@import url('https://fonts.googleapis.com/css2?family=DM Sans:wght@400;700&display=swap');


.myshifts-carousel-holder-mobile{
    background: var(--launch-colour2);
}

.myshifts-carousel-holder{
    background: var(--launch-colour2);
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
}


.shift-modification-btn{
    height: 50px;
    width: 50px;
    border: none;
    background: var(--launch-colour2);
    border-radius: 10px;
    transition: 250ms ease-in-out;
}
.shift-modification-btn:hover{background: var(--launch-colour);}
.shift-modification-btn:active{transform: scale(0.95);}

.my-shifts-calendar-holder-outer{
    background: var(--launch-colour2);
}

.my-shifts-calendar-holder{
    height: calc(100vh - 175px);
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border: 1px solid var(--launch-colour);
    border-radius: 15px;
}
.my-shifts-calendar-holder-mobile{
    height: calc(100vh - 232.5px);
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border: 1px solid var(--launch-colour);
    border-radius: 15px;
}



.my-shifts-calendar-inner-mobile{
    width: 100%;
    height: 100%;
}
.my-shifts-calendar-inner{
    height: 100%;
    width: calc(100% - 320px);
}
.my-shifts-calendar-inner-minimized{
    height: 100%;
    width: calc(100% - 80px);
}

.rbc-event-content{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 12px;
}
.rbc-agenda-event-cell,.rbc-agenda-date-cell, .rbc-agenda-time-cell{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: var(--text-colour);
}

.rbc-btn-group{
    background: var(--launch-colour2);
    padding: 5px;
    border-radius: 15px;
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    gap: 7.5px;
}

.rbc-btn-group button {
    /* background-color: white; */
    border: none;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    border: 1px solid var(--launch-colour2);
    color: #7d7d7d;
    transition: 0.15s ease-in-out;
    border-radius: none;
    font-size: 14px;
    cursor: pointer;
}

.rbc-btn-group button:hover {
    background: none;
    color: #7d7d7d;
    color: var(--worky-colour);
    border: 1px solid white;
    font-weight: 600;
    background: white;
    /* box-shadow: 0 2px 16px 0 rgba(0,0,0,0.1); */
}

.rbc-btn-group button:active {
    background:var(--worky-colour);
}

.rbc-btn-group > button:first-child:not(:last-child) {border-radius: 10px;}
.rbc-btn-group > button:last-child:not(:first-child) {border-radius: 10px;}
.rbc-btn-group > button:not(:first-child):not(:last-child) {border-radius: 10px;}





.rbc-btn-group button.rbc-active{
    background: var(--worky-colour);
    color: white;
    font-weight: 700;
    border: 1px solid var(--worky-colour);
}
.rbc-btn-group button.rbc-active:hover{
    background: var(--hightlight-colour);
    color: white;
    font-weight: 700;
    border: 1px solid var(--hightlight-colour);
}
.rbc-btn-group button.rbc-active:active{
    background: var(--hightlight-colour);
    color: white;
    font-weight: 700;
    border: 1px solid var(--hightlight-colour);
}
.rbc-btn-group button.rbc-active:enabled{
    background: var(--worky-colour);
    color: white;
    font-weight: 700;
    border: 1px solid var(--worky-colour);
}

.rbc-toolbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rbc-toolbar-label{
    font-family: 'DM Sans', sans-serif;
    font-size: 24px;
    /* color: var(--worky-colour); */
    color: var(--text-colour);
    font-weight: 800;
    margin-top: 10px;
}

.rbc-month-view{
    /* background-color: red; */
    width: calc(100% - 20px);
    margin: auto;
    border-radius: 10px;
    margin-top: 2.5px;
    margin-bottom: 10px;
    border: 1px solid var(--launch-colour);
}

.rbc-month-header{
    background: var(--launch-colour2);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 35px;

}

.rbc-header{
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: var(--text-colour); */
    color: #7d7d7d;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid var(--launch-colour);
}

.rbc-header + .rbc-header {
    border-left:none;
}
.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid var(--launch-colour2);
}
.rbc-off-range-bg {
    background: var(--launch-colour2);
}

.rbc-date-cell{
    /* background: red; */
    color: var(--text-colour);
}
.rbc-button-link{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    transition: 0.15s ease-in-out;
}
.rbc-button-link:hover{
    color: var(--worky-colour);
}
.rbc-month-row + .rbc-month-row {
    border-top: 1px solid var(--launch-colour);
}



.worky-footer-myshift{
    height: 40px;
    width: 100%;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    /* color: var(--launch-highlight); */
    color: #d7d7d7;
}


.shift-modification-holder{
    height: calc(100% - 40px);
    width: 280px;
    /* background: var(--launch-colour2); */
    padding: 10px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-direction: column;
    overflow-y: auto;
    border-radius: 10px;
    margin-left: 15px;
}

.shift-modification-holder-minimized{
    height: calc(100% - 40px);
    width: 55px;
    /* background: var(--launch-colour2); */
    /* padding: 10px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-direction: column;
    overflow-y: auto;
    border-radius: 10px;
    margin-left: 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;

}

.shift-modification-btn-holder{
    width: 100%;
    display: flex;
    /* margin-top: calc(100%); */
    margin-top:auto;
}
.shift-modification-btn-holder-minimized{
    width: 100%;
    display: flex;
    margin-top:auto;
}


.shift-modification-title{
    height: 40px;
    width: 100%;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--text-colour);
}
.shift-modification-title-minimized{
    height: 40px;
    width: 100%;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-colour);
    
}

.shift-modification-details-holder {
    width: 100%;
    /* background: blue; */
    padding-bottom: 10px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    /* min-height: 52.5px; or adjust according to the minimum height of a single element */
    max-height: 400px;
    /* overflow-y: auto; */
}

#gradient-blue{
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
    color: white;
}

.shift-modification-detail-edit{
    min-height: 50px;
    width: 100%;
    background: var(--launch-colour2);
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    color: #7d7d7d;
}
.shift-modification-detail-edit-minimized{
    height: 85px;
    width: 100%;
    background: var(--launch-colour2);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    color: #7d7d7d;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}



.shift-modification-detail-edit:hover{
    background: var(--launch-colour);
}
.shift-modification-detail:hover{
    background: var(--launch-colour);
}
.shift-modification-detail-edit:active{
    transform: scale(0.975);
}
.shift-modification-detail:active{
    transform: scale(1.05);
}

.shift-modification-detail{
    min-height: 50px;
    width: 100%;
    background: var(--launch-colour2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    color: #7d7d7d;
    transition: 0.15s ease-in-out;
}

.shift-modification-detail-icon{
    height: 40px;
    width: 40px;
    /* background: var(--launch-colour); */
    border-radius: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content:center;
    
}
.shift-modification-detail-text{
    height: 40px;
    width: 140px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 16.5px;
    display: flex;
    align-items: center;
    justify-content:center;
}
.shift-modification-detail-text-minimized{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content:center;
}
.shift-modification-detail-value{
    height: 40px;
    width: 40px;
    /* background: var(--launch-colour); */
    border-radius: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content:center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    
}
.shift-modification-detail-value-active{
    height: 40px;
    width: 40px;
    background: var(--launch-colour);
    border-radius: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content:center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
}
.shift-modification-detail-value-active-minimized{
    height: 40px;
    width: 40px;
    background: var(--launch-colour);
    border-radius: 10px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content:center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
}

.worky-new-shift-close{
    height:60px;
    width:60px;
    display: flex;
    align-items: center;
    justify-content:center;
    border: none;
    background: none;
    position: absolute;
    right:0px;
    top:0px;
    color: var(--text-colour);
    cursor: pointer;
}
.worky-new-shift-close:hover{
    color: var(--worky-colour);
}

.new-shift-modal{
    height: 70vh;
    width: 40vw;
    min-width: 400px;
    min-height: 650px;
    max-width: 600px;
    max-height: 700px;
    background: white;
    opacity: 0.88;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
}