:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}


.agency-billing-modal-payments-title-alt{
    margin-top: 20px;
    margin-bottom: 10px;

    width: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: var(--text-colour);
}


.agency-billing-modal-inner-notes-value{
    font-weight: 700;
    color:var(--text-colour);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    background: var(--launch-colour2);
    display: flex;
    width: calc(100% - 30px);
    padding-left: 15px;
    padding-right: 15px;
}

.agency-billing-modal-inner-notes-value-holder{
    /* background: red; */
    /* height: 25px; */
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    /* flex-direction: column; */
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
}

.agency-billing-modal-icon-alt{
    height: 60px;
    width: 60px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: var(--launch-colour2);
    border-radius: 100%;
}