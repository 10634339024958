:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@import url('https://fonts.googleapis.com/css2?family=DM Sans:wght@400;700&display=swap');


.new-shift-container-v5{
    height: calc(100vh - 160px);
    width: calc(100vw - 40px);
    margin: auto;
    margin-top: 20px;
    display: flex;
    align-items:center;
    justify-content: center;
}

.new-shift-container-v5-stepper-desktop{
    height: 100%;
    background: none;
    /* background: red; */
    width: 350px;
    display: flex;
    align-items:center;
    /* justify-content: center; */
    flex-direction: column;
}

.new-shift-container-v5-stepper-desktop-inner{
    height: 100%;
    /* background: blue; */
    width: 75%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.master-page-icon-desktop{
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    align-items:center;
    justify-content: center;
    border-radius: 7.5px;
}

.master-page-title-desktop{
    height: 60px;
    /* width: 100%; */
    padding-left: 20px;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content:left;
    /* margin-top: 30px; */
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 35px;
    color: var(--worky-colour);
}


.new-shift-v5-stepper-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    /* background: blue; */
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
}

.new-shift-v5-stepper-step-container{
    height: 60px;
    width: 100%;
    border: none;
    background: none;
    border-radius: 15px;
    /* background: var(--launch-colour); */
    /* cursor: pointer; */
    display: flex;
    align-items: center;
    justify-content: left;
}
#new-shift-v5-stepper-step-container-active{
    background: var(--launch-colour);
}

.new-shift-v5-stepper-step-icon{
    height: 35px;
    width: 35px;
    /* background: var(--worky-colour); */
    /* background: var(--launch-highlight); */
    
    border-radius: 100%;
    margin-left: 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
}
#passed-step{background: #4faf5f;}
#active-step{background: var(--worky-colour);}
#future-step{background: var(--launch-highlight);}

.new-shift-v5-stepper-step-container:hover{
    /* background: var(--launch-colour); */
}

.new-shift-v5-stepper-step-info{
    height: 100%;
    width: calc(100% - 65px);
    margin-left: 10px;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    flex-direction: column;
}

.new-shift-v5-stepper-step-title{
    height: 31px;
    /* background: blue; */
    width: calc(100% - 5px);
    display: flex;
    align-items: flex-end;
    justify-content: left;
    padding-left: 10px;
    font-size: 17px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--text-colour);
}


.new-shift-v5-stepper-step-subtitle{
    /* background: green; */
    width: calc(100% - 5px);
    display: flex;
    align-items: flex-end;
    justify-content: left;
    padding-left: 10px;
    /* font-size: 18px; */
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    color: #7d7d7d;
}










.new-shift-container-v5-shift-submission{
    height: 100%;
    background: white;
    border-radius:20px ;
    width: calc(100% - 350px);
    border: 1px solid var(--launch-colour);
}
.new-shift-v5-shift-page{
    /* height: calc(100% - 170px); */
    height: auto;
    margin-bottom:20px ;
    padding: 40px;
    padding-bottom: 0px;
    /* background: red; */
    /* padding-left: 40px;
    padding-right: 40px; */
}

.new-shift-v5-shift-page-title-holder{
    height: auto;
    margin: auto;
}

.new-shift-v5-shift-page-step{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    width: 100%;
    display: flex;
    align-items:center;
    color: var(--worky-colour);
}

.new-shift-v5-shift-page-title{
    display: flex;
    align-items:center;
    width: 100%;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--text-colour);
    font-size: 27px;
    padding-top: 2.5px;
}
.new-shift-v5-shift-page-subtitle{
    height: 25px;
    display: flex;
    align-items:center;
    width: 100%;
    color: #7d7d7d;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
}


.new-shift-v5-shift-page-content{
    /* background: var(--launch-colour2); */
    /* border-radius: 20px; */
    border-top: 2px solid var(--launch-colour);
    height: calc(100% - 240px);
    width: calc(100% - 80px);
    margin: auto;
    margin-top: 15px;
    margin-bottom: 30px;
}




/* PAGE 1 START */

.new-shift-v5-page-1-content-holder{
    height: 200px ;
    background: red;
}

.new-shift-v5-content-title{

}

.tester2{
    height:50px;
}


/* PAGE 1 END */








































.new-shift-v5-shift-submission-button-holder{
    height: 70px;
    width: calc(100% - 40px);
    margin: auto;
    /* background: red; */
    display: flex;
    /* align-items: center; */
    justify-content: space-between;

    
}

.new-shift-v5-shift-submission-button{
    height: 50px;
    width: 100px;
    border: none;
    border-radius: 15px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    color: var(--text-colour);
    transition: 0.1s ease-in-out;
}
#new-shift-v5-back{background: none;}
#new-shift-v5-back:hover{text-decoration: underline solid ;}
#new-shift-v5-back:active{transform: scale(0.95);}

#new-shift-v5-next{
    background: var(--worky-colour);
    color:white
}
#new-shift-v5-next:hover{
    font-weight: 600;
    box-shadow: 0 0px 5px 2px rgba(152, 152, 152, 0.5);
    /* background: var(--hightlight-colour); */
}
#new-shift-v5-next:active{
    transform: scale(0.95);
    /* background: var(--hightlight-colour); */
}


#new-shift-v5-next:disabled {
    background-color: #eee;
    color: #999; 
    cursor: not-allowed;
    pointer-events: none;
}


.new-shift-v5-shift-page-01{
    height: auto;
    width: 100%;
    /* background: red; */
    gap: 40px;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
}


.new-shift-v5-shift-page-1-side{
    width: calc(50% - 11px);
}


.new-shift-v5-shift-page-1{
    height: auto;
    width: 100%;
    max-width: 500px;
    padding-top: 20px;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.new-shift-v5-shift-page-1-selection-holder{
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* background: red; */
    padding-bottom: 15px;
    justify-content: left;
}

.new-shift-v5-shift-page-1-selection-title{
    width: 100%;
    /* background: blue; */
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 17px;
    color: var(--text-colour);
}

.new-shift-v5-shift-page-1-selection-select{
    height: 42.5px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid var(--launch-highlight);
    border-radius: 10px;
    font-family: 'DM Sans', sans-serif;
    color: #7d7d7d;
    font-weight: 500;
    background: var(--launch-colour2);
}
.new-shift-v5-shift-page-1-selection-select-mobile{
    height: 60px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid var(--launch-highlight);
    border-radius: 10px;
    font-family: 'DM Sans', sans-serif;
    color: #7d7d7d;
    font-weight: 500;
    background: var(--launch-colour2);
}



.num-jobbers-v5{
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid var(--launch-highlight);
    border-radius: 10px;
    font-family: 'DM Sans', sans-serif;
    color: #7d7d7d;
    font-weight: 500;
    background: var(--launch-colour2);
}


.num-jobbers-button-v5{
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: none;
    background: none;
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
    font-weight: 600;
    color: #7d7d7d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.num-jobbers-button-v5:hover{
    font-weight: 800;
    color: var(--worky-colour);
}
.num-jobbers-button-v5:active{
    font-weight: 800;
    color: var(--worky-colour);
    background: var(--launch-colour);
}

/* Hide spinners in WebKit browsers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinners in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.num-jobbers-value-v5{
    height: calc(100% - 15px);
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    font-family: 'DM Sans', sans-serif;
    color: #7d7d7d;
    font-weight: 500;
    background: none;
    background: white;
    font-size: 27px;
    border-radius: 15px;
}




.recurring-shift-modal{
    height: auto;
    width: 300px;
    padding: 30px;
    background: white;
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}


.recurring-shift-modal-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    color: var(--text-colour);
}

.recurring-shift-modal-dow-holder{
    height: 50px;
    width: auto;
    /* background: red; */
    padding-top: 15px;
    padding-bottom: 5px;
    
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.recurring-dow{
    background: var(--launch-colour2);
    border: none;
    border-radius: 10px;
    height: 35px;
    width: 35px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--text-colour);
    cursor: pointer;
}


.recurring-dow:hover{
    /* background: var(--hightlight-colour); */
    /* background: var(--worky-colour); */
    background: var(--launch-colour);
    text-decoration: underline solid var(--text-colour);
    /* color: white; */
}

.recurring-dow.selected {
    background:  var(--worky-colour);
    color: white;

}

.recurring-confirm{
    height: 45px;
    width: auto;
    padding-left: 25px;
    padding-right: 25px;
    background: var(--worky-colour);
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    margin-top: 10px;
}

.recurring-confirm:hover{
    background: var(--hightlight-colour);
}



.reciept-v5{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
}
.reciept-v5-mobile{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 17.5px;
    padding-right: 17.5px;
    border-radius: 15px;
    width: calc(100% - 35px);
    max-width: 500px;
    background: white;
}

.reciept-info-holder-v5{
    height: 50px;
    width: 100%;
    /* box-shadow: 0 0 3px rgba(0,0,0,0.08); */
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'DM Sans', sans-serif;
    color: var(--text-colour);
}

.accordian-title2{
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    color: var(--text-colour);
    /* padding-left: 10px; */
}

.reciept-item{
    height: 100%;
    width: auto;
    /* padding-left: 25px; */
    display: flex;
    align-items: center;
    font-weight: 500;
}
.reciept-value{
    font-weight: 800;
    height: 100%;
    width: auto;
    /* padding-right: 25px; */
    display: flex;
    align-items: center;
    font-family: 'DM Sans', sans-serif;
    color: var(--text-colour);
}







.new-shift-v5-stepper-bottom-mobile{
    height: 3px;
    width: 100%;
    background: var(--launch-colour);
}
.new-shift-v5-stepper-bottom-progress{
    height:100%;
    width: 0;
    /* background: var(--worky-colour); */
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
    transition: 0.15s ease-in-out;
}

#progress-step1{width: 20%;}
#progress-step2{width: 40%;}
#progress-step3{width: 60%;}
#progress-step4{width: 80%;}
#progress-step5{width: 100%;}





.new-shift-v5-stepper-holder-mobile{
    height: auto;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;
    gap: 10px;
}

.new-shift-v5-stepper-title-mobile{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--text-colour);
    font-size: 27px;
}
.new-shift-v5-stepper-subtitle-mobile{
    color: #7d7d7d;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.new-shift-container-v5-main-mobile{
    height: auto;
    /* background: red; */
    width: 95%;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 55px;
    display: flex;
    align-content: center;
    justify-content: center;
}


.new-shift-v5-shift-submission-button-holder-mobile{
    position: fixed;
    bottom: 72.5px;
    height: 55px;
    width: 95vw;
    padding-top: 5px;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.new-shift-v5-shift-submission-button-mobile{
    height: 50px;
    border: none;
    border-radius: 25px;
    
}

.new-shift-container-v5-mobile{
    padding-bottom: 75px;
}

#new-shift-v5-back-mobile{
    background: none;
    width: 50px;
    color: #7d7d7d;
    background: var(--launch-colour);
}
#new-shift-v5-back-mobile:hover{text-decoration: underline solid ;}


#new-shift-v5-next-mobile{
    background: var(--worky-colour);
    /* background: linear-gradient(90deg, #5a9bd6, #2c68bd); */
    color:white;
    width: calc(100% - 60px);
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
}
#new-shift-v5-next-mobile:active{
    transform: scale(0.975);
    /* background: var(--hightlight-colour); */
}
#new-shift-v5-next-mobile:disabled {
    background-color: #eee;
    color: #999; 
    cursor: not-allowed;
    pointer-events: none;
}


.new-shift-daypicker-holder-mobile{
    width: 100%;
    margin-top: 10px;
    border-radius: 15px;
    background: white;
    padding-top: 75px;
    padding-bottom: 75px;
    border: 1px solid var(--launch-colour);
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 250px;
    height: auto;
    /* background: var(--launch-colour); */
    /* margin-left: auto; */
    /* margin-right: auto; */
}

.num-jobbers-v5-mobile{
    height: 100px;
    width: 100%;
    /* background: red; */
    background: var(--launch-colour);
    background: white;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.num-jobbers-button-v5-mobile{
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: none;
    background: none;
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
    font-weight: 600;
    color: #7d7d7d;
    display: flex;
    align-items: center;
    justify-content: center;
}
.num-jobbers-value-v5-mobile{
    height: calc(100% - 15px);
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    font-family: 'DM Sans', sans-serif;
    color: #7d7d7d;
    font-weight: 500;
    background: none;
    /* background: white; */
    /* background: var(--launch-colour2); */
    font-size: 57px;
    border-radius: 15px;
}


.prev-jobber-holder {
    height: auto;
    width: 100%;
    border: 1px solid var(--launch-highlight);
    border-radius: 10px;
    font-family: 'DM Sans', sans-serif;
    color: #7d7d7d;
    font-weight: 500;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 10px;
    gap: 10px;
    max-height: 280px;
    overflow: hidden;
    overflow-y: auto;

}


.prev-jobber-search-holder{
    height: 45px;
    /* max-width: 480px; */
    width: calc(100% - 20px);
    padding:10px;

    background: var(--launch-colour2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    gap: 10px;
    z-index: 5;
    position: sticky; /* Make it sticky */
    top: 0;
}

.prev-jobber-search{
    height: 40px;
    width: calc(75% - 10px);
    padding-left: 15px;
    border: none;
    border-radius: 10px;
    border: 1px solid var(--launch-colour);
    font-family: 'DM Sans', sans-serif;
}

.prev-jobber-select-all-btn{
    width: 100px;
    height: 40px;
    border: none;
    background: var(--worky-colour);
    color: white;
    border-radius: 10px;
    cursor: pointer;
    font-size: 13px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    transition: 150ms ease-in-out;
}

.prev-jobber-select-all-btn:hover{
    background: var(--hightlight-colour);
}
.prev-jobber-select-all-btn:active{
    transform: scale(0.98);
}

#prev-jobber-card-v5-disabled {
    height: 55px; /* Fixed height */
    min-height: 55px;
    transition: 200ms ease-in-out;
    width: calc(100% - 20px);
    background: #f0f0f0; /* Light gray background to indicate disabled */
    border-radius: 15px;
    color: #b0b0b0; /* Light text color */
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.6; /* Reduce opacity for a disabled look */
}

#prev-jobber-card-v5-disabled input,
#prev-jobber-card-v5-disabled button,
#prev-jobber-card-v5-disabled select,
#prev-jobber-card-v5-disabled textarea {
    pointer-events: none;  /* Disable clicking */
    opacity: 0.6; /* Reduce visibility */
    cursor: not-allowed; /* Show "not allowed" cursor */
}

.prev-jobber-card-v5 {
    height: 55px; /* Fixed height */
    min-height: 55px;
    transition: 200ms ease-in-out;
    width: calc(100% - 20px);
    background: white;
    border-radius: 15px;
    color: #7d7d7d;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.prev-jobber-card-v5:hover{
    /* INSERT BOX SHADOW */
    box-shadow: 0 0px 5px rgba(11, 11, 11, 0.1);
}

.prev-jobber-card-v5-side{
    height: 100%;
    width: auto;
    /* background: red; */
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.prev-jobber-card-v5-name{
    font-weight: 600;
}
.prev-jobber-card-v5-icon{
    height: 35px;
    width: 35px;
    min-height: 35px;
    min-width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    /* background: var(--launch-colour2); */
    overflow: hidden;
}

.prev-jobber-card-v5-icon img{
    height: 37.5px;
    width:37.5px;
    
    /* opacity: 0.3; */
}

.prev-jobber-card-v5-select{
    color: white;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
}

.prev-jobber-card-v5-rating{
    display: flex;
    align-items: center;
    justify-content: center;
}
