:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}


.jobber-paystub-container{
    height: auto;
    width: 100%;
    background: var(--launch-colour2);
    padding-top: 10px;
}

.jobber-paystub-holder{
    height: auto;
    width: calc(100% - 20px);
    margin: auto;
    border-radius: 10px;
}


.jobber-paystub-modal{
    height: auto;
    min-height: 200px;
    max-height: 90vh;
    width: 100vw;
    background: white;
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.jobber-paystub-modal-title{
    height: 55px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: calc(100% - 40px);
    margin: auto;
    font-family: 'DM Sans', sans-serif;
    color: var(--text-colour);
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jobber-paystub-modal-icon-download{
    border: none;
    border-radius: 100%;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7d7d7d;
    height: 35px;
    width: 35px;
}
.jobber-paystub-modal-icon{
    border: none;
    background: none;
    border-radius: 100%;
    /* background: var(--launch-colour2); */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7d7d7d;
}

.jobber-paystub-modal-shift-block-holder{
    height: auto;
    max-height: 500px;
    width: calc(100% - 40px);
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    overflow-y: scroll;
    /* background: red; */
}

.jobber-paystub-modal-shift-block{
    height: 50px;
    width: 100%;
    background: var(--launch-colour2);
    border-radius: 15px;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.jobber-paystub-modal-shift-block-date{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    color: var(--dark-text);
    font-size: 15px;
    font-weight: 600;
    gap: 10px;
}
.jobber-paystub-modal-detail-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-direction: column;
}
.jobber-paystub-modal-detail-holder{
    height: 30px;
    width: 90%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.jobber-paystub-modal-detail-title{
    font-family: 'DM Sans', sans-serif;
    color: var(--dark-text);
    font-size: 13px;
    font-weight: 500;
}

.jobber-paystub-modal-detail-value{
    font-family: 'DM Sans', sans-serif;
    color: var(--dark-text);
    font-size: 13px;
    font-weight: 600;
}

.jobber-paystub-modal-btn-holder{
    height: 50px;
    width: calc(100% - 40px);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* background: red; */
    gap: 5px;
    margin-top: 15px;
}

.jobber-paystub-modal-btn{
    height: 50px;
    width: 50px;
    border: none;
    border-radius: 18px;
    color:white;
    background: var(--worky-colour);
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}


.daterange-col{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
}
.earnings-col{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
}
.earnings-col-more-btn{
    height: 38px;
    width: 38px;
    border: none;
    border-radius: 100%;
    background: var(--launch-colour);
    color: var(--text-colour);
    display: flex;
    align-items: center;
    justify-content: center;
}


.jobber-paystub-header{
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: var(--dark-text);
}

.no-overflow-cell {
    overflow: hidden;
    white-space: nowrap; /* Ensures text doesn't wrap */
    text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
}

.jobber-paystub-modal-top-block-holder{
    height: 60px;
    width: calc(100% - 40px);
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.jobber-paystub-modal-top-block{
    height: 100%;
    width:22.5%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /* gap: 2.5px; */
}

.jobber-paystub-modal-top-block-title{
    height: 12.5px;
    width: 100%;

    font-size: 9.5px;
    display: flex;
    /* text-indent: 3px; */
    padding-top: 3px;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    color: var(--dark-text);
}
.jobber-paystub-modal-top-block-value{
    height: 45px;
    width: 100%;
    background: var(--launch-colour2);
    border-radius: 12.5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--dark-text);
}