:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@import url('https://fonts.googleapis.com/css2?family=DM Sans:wght@400;700&display=swap');

@keyframes expand {
    0% {
        transform: scale(1);
    }
    50% {
        transform:  scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}


.product-container{
    background: white;
}


.worky-product-container{
    height: 650px;
    width: 90%;
    margin: auto;
    /* background-color: var(--launch-colour2); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 10px; */
    margin-top: 80px;
}

.worky-product-content-container{
    height: 575px;
    width: 50%;
    /* background: var(--launch-highlight); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 20;
}

.worky-product-title{
    font-size: 80px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--text-colour);
    display: flex;
    /* align-items: center; */
    justify-content: center;
    gap: 5px;
}

.worky-product-subtitle{
    margin-top: 15px;

    width: 80%;
    height: auto;
    line-height: 22px;
    color: #7f7f7f;
}

.worky-product-button{
    margin-top: 32.5px;
    height: 45px;
    padding-left: 50px;
    padding-right: 35px;
    border: none;
    border-radius: 10px;
    background: var(--launch-colour2);
    color: #7d7d7d;
    color: var(--worky-colour);
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
    font-weight: 700;
    transition: 0.15s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background: var(--worky-colour);
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
    color: white;
}
.worky-product-button-mobile{
    margin-top: 32.5px;
    height: 45px;
    padding-left: 35px;
    padding-right: 35px;
    border: none;
    border-radius: 10px;
    background: var(--launch-colour2);
    color: #7d7d7d;
    color: var(--worky-colour);
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
    font-weight: 700;
    transition: 0.15s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background: var(--worky-colour);
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
    color: white;
}

.worky-product-button-reverse{
    margin-top: 32.5px;
    height: 45px;
    padding-right: 50px;
    padding-left: 35px;
    border: none;
    border-radius: 10px;
    background: var(--launch-colour2);
    color: #7d7d7d;
    color: var(--worky-colour);
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
    font-weight: 700;
    transition: 0.15s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background: var(--worky-colour);
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
    color: white;
}


.worky-product-button:hover{
    animation: expand 0.8s ;

}

.worky-product-button-reverse:hover{
    animation: expand 0.8s ;

}

.worky-product-img-container{
    height: 575px;
    width: 48%;
    transition: 0.15s ease-in-out;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
}
.worky-product-img-container img{
    height: 575px;
    transition: 0.15s ease-in-out;
}
.worky-product-img-container img:hover{
    transform: scale(1.29);
}


/* .worky-product-img-container:hover{
    background-color: var(--launch-colour2);
} */



.product-tabs-container{
    height: auto;
    width: auto;
    /* background: red; */
    background: white;
    margin: auto;
    margin-top: 100px;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 40px; */
}


.product-tabs-container-inner{
    height: 50px;
    width: auto;
    border-radius: 20px;
    padding: 5px;
    /* background: red; */
    background: var(--launch-colour2);
    margin: auto;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 40px; */
}

.product-tab{
    height: 100%;
    width: 180px;
    border: none;
    background: none;
    /* background: white; */
    border-radius: 15px;
    cursor: pointer;
    transition: ease-in-out 0.15s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:5px ;
    color: #7d7d7d;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
}

.product-tab:hover{
    /* background: var(--launch-colour); */
    font-weight: 700;
    color: var(--worky-colour);
}




#product-tab-active{
    background: white;
    color: var(--worky-colour);
    font-weight: 700;
}






.products-page-container{
    height: auto;
    padding-bottom:20px ;
}

.products-display-container{
    height: auto;
    background-color: white;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
}
.products-display-container-mobile{
    height: auto;
    background-color: white;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 170px;
}


.products-display-title{
    font-size: 70px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--text-colour);
}
.products-display-subtitle{
    width: 60%;
    height: auto;
    line-height: 20px;
    font-size: 14px;
    color: #7f7f7f;
    margin-top: 20px;

}

.products-display-carousel-holder{
    /* height: 270; */
    margin-top: 30px;
    /* background: var(--launch-colour2); */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 25px;
    gap: 12.5px;
}

#wide-carousel-item{
    width: 610px;
}

.product-display-carousel-item{
    height: 650px;
    width: 900px;
    border: none;
    /* background: red; */
    overflow: hidden;
    border-radius: 20px;
    transition: 0.15s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-display-carousel-item img{
    height: 800px;
    width: 800px;
    margin-top: -111px;
}
#desktop-smart-match-product{
    height: 700px;
    width: 700px;
    margin-top: -10px;
}
#desktop-ai-product{
    /* height: 700px; */
    /* width: 700px; */
    margin-top: 10px;
}

.modal-carousel-item-close{
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    position: absolute;
    top:12.5px;
    right: 12.5px;
    cursor: pointer;
    border-radius: 100%;
}
.modal-carousel-item-close:hover{
    color: var(--worky-colour);
    background: var(--launch-colour2);
}

.modal-carousel-item{
    height: 550px;
    width: 800px;
    background: white;
    border-radius: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.modal-carousel-img-holder{
    height: 510px;
    width: 760px;
    /* background: var(--launch-colour2); */
    margin: auto;
    margin-top: 20px;
    border-radius: 17.5px;

}





.product-display-carousel-more{
    height: 170px;
    width: 70px;
    border: none;
    background: white;
    border-radius: 20px;
}

.products-plan-container {
    height: auto;
    width: 90%;
    /* background: red; */
    margin: auto;
    /* padding-top: 75px; */
    
}

.products-plan-top-container {
    height: 50px;
    width: 100%;
    background: white;
    display: flex;
    gap: 10px;
    align-items: center;
}
.products-plan-top-container-mobile {
    height: 50px;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.products-plan-title {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--text-colour);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 35px;
}

.products-plan-billing-switch-container {
    height: 40px;
    background:var(--launch-colour2);
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 5px;
    border-radius: 15px;

}

.products-plan-billing-switch {
    height: 40px;
    width: auto;
    padding-right: 15px;
    padding-left: 15px;
    color: #7f7f7f;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    border: none;
    background: none;
    border-radius: 10px;
    cursor: pointer;
}

#products-plan-billing-switch-active{
    background: white;
    color: var(--worky-colour);
}

.products-plan-bottom-container {
    height: 520px;
    width: 100%;
    display: flex;
    align-items:flex-start;
    justify-content: center;
    gap: 50px;
    margin-top: 35px;
}
.products-plan-bottom-container-mobile {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: space-between;
    margin-top: 120px;
    gap: 25px;
}

.products-plan-option {
    height: auto;
    padding-bottom: 20px;
    width: calc(30% - 15px);
    background: var(--launch-colour2);
    border-radius:30px ;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.15s ease-in-out;
    /* justify-content: center; */

}
.products-plan-option-mobile {
    height: auto;
    padding-bottom: 20px;
    width: 90%;
    margin: auto;
    background: var(--launch-colour2);
    border-radius:30px ;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.15s ease-in-out;
    /* justify-content: center; */

}


.products-plan-option:hover {
    /* background: var(--launch-colour); */
    transform: scale(1.03);
    /* border: 3px solid var(--launch-colour2); */
}

#most-popular{
    color: #e7f1ff;
    background: var(--worky-colour);
}
#enhanced-plan{
    color: #e7f1ff;
    background: var(--worky-colour);
}

.products-plan-inner-tag {
    margin-top: 25px;
    height: 20px;
    width: auto;
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
    /* background: white; */
    color: #7f7f7f;
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-weight: 700;
    color: var(--worky-colour);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    color: white;
}
.products-plan-inner-tag-mobile {
    /* margin-top: 25px; */
    height: 20px;
    width: auto;
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
    /* background: white; */
    color: #7f7f7f;
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-weight: 700;
    color: var(--worky-colour);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    color: white;
}

.save-tag{
    margin-left: 20px;
    height: 20px;
    width: auto;
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
    
    
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 700;
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;

    color: #e7f1ff;
    background: var(--worky-colour);
}
.save-tag-mobile{
    height: 20px;
    width: auto;
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
    
    
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 700;
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;

    color: #e7f1ff;
    background: var(--worky-colour);
}

.products-plan-inner-title {
    margin-top: 20px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* text-align: left; */
    /* text-indent: 25px; */
    /* background: red; */
    color: var(--dark-text);
    font-family: 'DM Sans', sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
}
.products-plan-inner-title-mobile {
    margin-top: 10px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* text-align: left; */
    /* text-indent: 25px; */
    /* background: red; */
    color: var(--dark-text);
    font-family: 'DM Sans', sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
}

.products-plan-inner-subtitle {
    height: 50px;
    width: 70%;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    color: var(--text-colour);
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 20px;
    
    /* background: red; */
}
#enhanced-subtitle{
    margin-bottom: 0px;
}

.products-plan-inner-price {
    height: 50px;
    /* margin-top: 10px; */
    /* margin-bottom: 5px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    color: var(--text-colour);
    font-family: 'DM Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
}

.products-plan-inner-subprice {
    /* height: 50px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    color: var(--text-colour);
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 20px;

}

.products-plan-inner-button {
    /* margin-top: 20px; */
    margin-bottom: 20px;
    height: 45px;
    width: 80%;
    background: none;
    border: none;
    border-radius: 10px;
    color: white;
    background: var(--worky-colour);
    cursor: pointer;
    transition: 0.15s ease-in-out;
    font-family: 'DM Sans', sans-serif;
    font-size: 17px;
    font-weight: 600;
}

.products-plan-inner-button:hover {
    background: var(--hightlight-colour);

}

.products-plan-inner-features-holder {
    height: auto;
    width: 80%;

    /* background-color: red; */
}

.products-plan-inner-features-title {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--text-colour);
}

.products-plan-inner-feature {
    height: 22px;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: left;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: var(--text-colour);

}



.common-questions-holder{
    margin-top: 75px;
    padding-top: 75px;
}

.common-questions-title{
    font-size: 50px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--text-colour);
}

.common-questions-subtitle{
    width: 100%;
    height: auto;
    line-height: 20px;
    font-size: 15px;
    color: #7f7f7f;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 18px;
    /* margin-top: 20px; */
}

.common-questions-button{
    height: 40px;
    width: 120px;
    background: var(--worky-colour);
    color:white;
    border: none;
    border-radius: 10px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    cursor: pointer;
}

.common-questions-button:hover{
    background: var(--hightlight-colour);

}

.common-questions-accordian{
    height: auto;
    width: 70%;
    margin: auto;
    margin-top: 75px;
}
.common-questions-accordian-mobile{
    height: auto;
    width: 90%;
    margin: auto;
    margin-top: 75px;
}

.accordian-question{
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 17px;
    text-align: left;
    color: var(--dark-text);
}

.accordian-answer{
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    /* color: var(--text-colour); */
    color: #7d7d7d;
    min-height: 50px;
    height: auto;
    text-align: left;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    /* text-indent: 20px; */
}


.products-tabs-container-mobile{
    height: 55px;
    position: fixed;
    width: calc(100% - 10px);
    /* margin-left: 2.5%; */
    /* max-width: 500px; */
    /* background: var(--launch-colour2); */
    /* border-radius: 15px; */
    background-color: rgba(250, 250, 250, 0.85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    z-index: 1000;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
    transition: 0.15s ease-in-out;
}

.product-tab-mobile{
    height: 100%;
    width: auto;
    background: white;
    width: 60px;
    border: none;
    border-radius: 15px;
    transition: 0.15s ease-in-out;
    color: #7d7d7d;
    color: var(--launch-highlight);
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
}
#product-tab-mobile-active{
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    color: var(--worky-colour);
    width: 50%;
    transition: 0.15s ease-in-out;
    /* color: #7d7d7d; */
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    font-size: 17px;
}
.products-display-subtitle-mobile{
    width: 95%;
    height: auto;
    line-height: 20px;
    font-size: 14px;
    color: #7f7f7f;
    margin-top: 20px;

}


.products-display-carousel-holder-mobile{
    /* height: 270; */
    width: 95%;
    height: 330px;
    margin-top: 40px;
    /* background: var(--launch-colour2); */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 25px;
    overflow: hidden;
}
.products-display-carousel-holder-mobile img{
    height: 330px;
    border-radius: 15px;
}