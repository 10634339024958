:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@import url('https://fonts.googleapis.com/css2?family=DM Sans:wght@400;700&display=swap');

.payments-header-holder{
    height: 100px;
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    background: var(--launch-colour2);
    /* padding-top: 25px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: blue; */
}

.payments-header-holder-mobile{
    height: 100px;
    width: 95%;
    padding-left: 2.5%;
    padding-right: 2.5%;
    background: var(--launch-colour2);
    padding-top: 5px;
    display: flex;
    align-items: center;
}

.payments-header{
    height: 100%;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.payments-header-title-holder{
    height: 50px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 30px;
    color: #d7d7d7;
}

.payments-header-subtitle{
    height: 25px;
    width: 100%;
    /* background: green; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: #7d7d7d;
    padding-left: 7.5px;
}

.payments-header-title-switch{
    height: 50px;
    width: auto;
    background: none;
    border: none;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 35px;
    /* cursor: pointer; */
    /* background: blue; */
}

.payments-header-title-switch:hover{
    color: var(--hightlight-colour);
}
#payments-header-active{
    color: var(--worky-colour);
}
#payments-header-inactive{color: #d7d7d7;}
#payments-header-inactive:hover{color: var(--launch-highlight);}

.payments-main-comp-holder{
    height: auto;

    background: var(--launch-colour2);
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
}

.payments-main-comp-inner{
    height: auto;
    width: calc(100% - 40px);
    background: var(--launch-colour2);
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}


.receivables-top-card-holder{
    height: auto;
    width: 100%;
    /* background: white; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-top: 10px;
    /* overflow-x: auto;
    overflow-y: hidden; */

    /* box-shadow: 0 4px 32px 0 rgba(222, 222, 222, 0.1); */
    /* border-radius: 15px; */
    /* border: 1px solid var(--launch-colour); */
}
.receivables-top-card-holder-mobile{
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    /* background: red; */
}
.receivables-top-card{
    height: 175px;
    /* min-width: 500px; */
    width: auto;
    min-width: 175px;
    border-radius: 15px;
    /* background: white; */
    /* border: 1px solid var(--launch-colour); */
}
.receivables-top-card-mobile{
    height: auto;
    /* min-width: 230px; */
    border-radius: 25px;
    scroll-snap-align: start;
    display: flex;
    flex: 0 0 auto;
    /* background: white; */
    /* border: 1px solid var(--launch-colour); */
}

.receivables-main-table-holder{
    height: auto;
    min-height: 200px;
    background: white;
    border-radius: 15px;
    /* border: 1px solid var(--launch-colour); */
    width: 100%;
}

.receivables-main-table-holder-mobile{
    height: auto;
    max-height: calc(100vh - 190px);
    background: white;
    border-radius: 15px;
    border: 1px solid var(--launch-colour);
    width: 100%;
    margin-bottom: 85px;
}

.worky-footer-payments{
    height: 70px;
    width: 100%;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    /* color: var(--launch-highlight); */
    color: #d7d7d7;
}

.payments-row-download-btn{
    height: 32.5px;
    width: 32.5px;
    border: none;
    background: var(--launch-colour2);
    border-radius: 20px;
    color: var(--launch-highlight);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* margin-left: 19px; */
}

.billing-header{
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: var(--text-colour);
    font-weight: 700;
    background: white;
}

.payments-row-download-btn:hover{
    background: var(--worky-colour);
    color: white;
}

.download-col{
    display: flex;
    align-items: center;
}

.invoice-cell-holder{
    display: flex;
    align-items: center;
}
.invoice-cell-icon{
    height: 32.5px;
    width: 32.5px;
    border: none;
    background: var(--launch-colour2);
    border-radius: 20px;
    color: var(--launch-highlight);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 12.5px;
}

.invoice-cell-text{
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 500;
}

.invoice-cell-btn{
    height: 38px;
    width: 38px;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100%;
    color: var(--launch-highlight);
    transition: 75ms ease-in-out;
    cursor: pointer;
}

.invoice-cell-btn:hover{
    background: var(--worky-colour);
    color: white;
}

.billing-tag-holder{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.billing-tag{
    height: 19px;
    padding-left: 10px;
    padding-right: 10px;
    /* padding-bottom: 1px;
    padding-top: 1px; */
    border-radius: 7.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
}


#bill-paid {
    background-color: #eefcf4; 
    color: #3a7443; 
    border:1px solid #a9e2bc;
}

#bill-unpaid {
    background-color: #fbf1f1; 
    color: #b01211; 
    border:1px solid #eec8c8;
}

#bill-partially-paid {
    background-color: #fdfae6; 
    color: #71350d; 
    border:1px solid #e4dd8a;
}

#bill-overdue {
    background-color: #fbf1f1; 
    color: #b01211; 
    border:1px solid #eec8c8;
}

.payments-cards-inner {
    height: calc(100% - 40px);
    padding: 20px;
    padding-left: 0px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    overflow-x: auto;
    overflow-y: hidden;
}
.payments-cards-inner-mobile{
    height: calc(100% - 40px);
    /* padding: 20px; */
    padding-left: 0px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    scroll-snap-type: x mandatory;

}

.payments-card-summary{
    height: 133px;
    width: 200px;
    background: white;
    border-radius: 12px;
    transition: 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    min-width: 180px;
    border: 1px solid var(--launch-colour);
    scroll-snap-align: start;
    flex: 0 0 auto;
}
.payments-card-summary:hover{box-shadow: 0 4px 32px 0 rgba(222, 222, 222, 0.9);}

.payments-card-summary-info{
    height: 100px;
    width: 100%;
    padding-top: 5px;
}
.payments-card-summary-title{
    /* height: 30px; */
    padding-bottom: 7.5px;
    width: 100%;
    color: var(--text-colour);
    font-size: 15px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
}


.payments-card-summary-title-holder{
    height: auto;
    width: calc(100% + 40px);
    /* background: red; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}

.payments-card-summary-title-v2{
    font-size: 18px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
}

.payments-card-summary-icon-v2{
    height: 42.5px;
    width: 42.5px;
    border-radius: 40px;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7d7d7d;
}

.payments-card-summary-value-holder{
    height: 45px;
    width: calc(100% + 40px);
    background: var(--launch-colour);
    border-radius: 10px;
    margin-top: 7.5px;
}

.payments-card-summary-value-inner{
    height: 100%;
    width: 50%;
    border-radius: 10px;
    background: var(--worky-colour);
}

.payments-card-summary-value-text{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--launch-colour2);
}



.payments-card-summary-value-holder-value{
    height: 40px;
    width: calc(100% + 40px);
    /* background: red; */
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 35px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--dark-text);
}

.agency-section-title{
    /* height: 50px; */
    width: 100%;
    display:flex;
    align-items: flex-end;
    font-size: 25px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    padding-left: 5px;
}