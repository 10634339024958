:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@import url('https://fonts.googleapis.com/css2?family=DM Sans:wght@400;700&display=swap');


.worky-app{
    /* height: calc(100vh - 20px); */
    height:100vh;
    width: 100vw;
    background: var(--launch-colour2);
    /* padding-top: 20px; */
}

.worky-app-mobile{
    /* height: calc(100vh - 20px); */
    height:100vh;
    width: 100vw;
    background: var(--launch-colour2);
    padding-bottom: 111px;
    /* overflow-x: hidden; */
    /* padding-top: 20px; */
}

.worky-app-header {
    background: white;
    height: 50px;
    width: 100%;
    margin: auto;
    border-bottom: 1px solid var(--launch-colour);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.worky-app-header-left {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 25px;
    font-family: 'DM Sans', sans-serif;
    font-size: 30px;
    font-weight: 200;
    color: var(--launch-highlight);
    background: none;
    border: none;
    width: auto;
}

.worky-app-header-left-logo {
    font-size: 24px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--worky-colour);
    margin-top: -3px;
}

.worky-app-header-left-label {
    font-family: "mendl-sans-dusk", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: var(--text-colour);
    white-space: nowrap; /* Ensures text stays on one line */
}


.worky-app-header-middle {
    height: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 0px;
    overflow: hidden; /* Prevents overflow issues */
    scrollbar-width: none; /* Hides default scrollbar */

}

.worky-app-header-middle-left::-webkit-scrollbar {
    display: none;
}

.worky-app-header-middle::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 50px; /* Adjust fade width */
    height: 100%;
    /* background: linear-gradient(to left, white, rgba(255, 255, 255, 0)); */
    pointer-events: none; /* Allows interactions with buttons */
}


.worky-app-header-middle-left {
    display: flex;
    align-items: center;
    gap: 10px;
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents buttons from stacking */
    max-width: calc(100vw - 300px); /* Adjusts max width dynamically */
    
}



.worky-app-header-middle-option{
    height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    background: none;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    color: var(--text-colour);
    color: #7d7d7d;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap; /* Ensures text stays on one line */
    color: var(--launch-highlight);
    transition: 0.1s ease-in-out; 
}

.worky-app-header-middle-search{
    height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100px;
    background: none;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    color: var(--text-colour);
    color: #7d7d7d;
    font-size: 13px;
    font-weight: 600;
    border: 1px solid var(--launch-colour);
    color: var(--launch-highlight);
    transition: 0.1s ease-in-out; 
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.worky-app-header-middle-search:hover{
    color: #7d7d7d;
    border: 1px solid var(--launch-highlight);
}

.worky-app-header-middle-option:hover{
    background: var(--launch-colour2);
    color: var(--worky-colour);
    font-weight: 600;
    /* border: 1px solid var(--launch-colour); */

}

#worky-app-header-middle-option-active{
    background: var(--launch-colour2);
    color: var(--worky-colour);
    font-weight: 600;
}












.worky-app-header-right{
    height: 45px;
    /* float: right; */
    /* background: blue; */
    /* width: 250px; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    justify-content: flex-start;
    /* padding-right: 20px; */
    flex-direction: column;
    margin-left: auto;

}

.worky-app-header-right-inner{
    display: flex;
    align-items: center;
    justify-content:flex-start;
    margin-left: auto;
    padding: 5px;
    padding-right: 20px;
    padding-left: 10px;
    border-radius: 12.5px;
    gap: 10px;
    transition: 0.1s ease-in-out;
}

.worky-app-header-right-user-account-holder-item-top{
    font-size: 15px;
    font-weight: 500;
    color: var(--text-colour);
    font-family: 'DM Sans', sans-serif;
    /* background: red; */
    /* margin-top: -2px; */
    height: 40px;
    display: flex;
    align-items:flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    width: 85%;
    margin: auto;
    padding: 7.5px;
    border-radius: 15px;
    
}

.worky-app-header-right-user-account-holder-item{
    height: 40px;
    width: calc(90% - 30px);
    margin: auto;
    border-radius:10px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    color:var(--launch-highlight);
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.worky-app-header-right-user-account-holder-item:hover{
    background: var(--launch-colour2);
    /* color: var(--text-colour); */
}
.worky-app-header-right-user-account-holder-item:active{
    background: var(--launch-colour2);
    color: var(--worky-colour);
}


.worky-app-header-right-title{
    font-size: 10px;
    font-weight: 400;
    padding: 1.5px;
    background: var(--launch-colour2);
    background: #73a3f7;
    color:white;
    padding-left: 7.5px;
    padding-right: 7.5px;
    border-radius: 5px;
    /* text-align: left; */
    /* background: blue; */
    /* width: 100%; */
}

.worky-app-header-right-icon{
    height: 36px;
    width: 36px;
    border: none;
    /* background: var(--launch-highlight); */
    /* background: var(--launch-colour); */

    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    color: #7d7d7d;
    font-family: 'DM Sans', sans-serif;
    /* background: #d7d7d7; */
    transition: 250ms ease-in-out;
    background: none;
    background: var(--launch-colour2);
}
.worky-app-header-right-icon:hover{
    background: var(--launch-colour);
    color: var(--worky-colour);
    /* color: var(--text-colour); */
}

.worky-app-header-right-user-account-holder-closed{
    display: none;
}

.worky-app-header-right-user-account-holder{
    height: auto;
    width: 200px;
    background: white;
    /* background: var(--launch-colour); */
    
    border-radius: 20px;
    position: absolute;
    z-index: 2;
    top: 60px;
    right: 10px;
    padding-top: 5px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* box-shadow: 0 0px 5px 2px rgba(111, 111, 111, 0.1); */
    box-shadow: 0 4px 32px 0 rgba(0,0,0,0.1);
}









.worky-top-shelf{
    height: 260px;
    width: calc(100% - 50px);
    /* background: red; */
    margin: auto;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 20px; */
}



.worky-top-shelf-side{
    height: 100%;
    
    /* background: blue; */
    width: calc(50% - 15px);
}

.worky-top-shelf-side-title{
    height: 35px;
    width: 100%;
    font-size: 25px;
    text-align: left;
    text-indent: 15px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 800;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 10px;
}



.worky-top-shelf-side-button{
    height: calc(100% - 55px);
    width: 100%;
    background: none;
    border: none;
    border-radius: 25px;
    transition: 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}
#shelf-newshift{
    background: linear-gradient(90deg, #6f9aff, #5689fc);
    color: white;
}
#shelf-prevshift{
    background: linear-gradient(90deg, #ececec, #ececec);
    color: var(--text-colour);
}

.worky-top-shelf-side-button:hover{
    box-shadow: 0 4px 32px 0 rgba(0,0,0,0.1);
}

.worky-top-shelf-side-button:active{
    transform: scale(0.98);
}

.worky-top-shelf-side-button-icon-holder{
    height: 100%;
    width: 40%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.worky-top-shelf-side-button-icon-holder-inner{
    height: 111px;
    width: 111px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 100px; */
    /* color: white; */
}

.worky-top-shelf-side-button-right{

    width: 60%;
    /* background: red; */
    display: flex;
    margin-top: -5px;    
    align-items: left;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    text-align: left;
}

.worky-top-shelf-side-button-right-title{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 35px;
    color: white;
}

.worky-top-shelf-side-button-right-subtitle{
    font-size: 18px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-style: normal;
    color: white;
}





.worky-top-shelf-option{
    height: 120px;
    width: 120px;
    border: none;
    background: white;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.15s ease-in-out;
}



.current-shifts-option{
    height: 32.5px;
    padding-left: 15px;
    padding-right: 15px;
    border:  1px solid var(--launch-highlight);
    border-radius: 13px;
    background: none;
    color: #7d7d7d;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    transition: 0.1s ease-in-out;
    cursor: pointer;
}
.current-shifts-option:hover{
    border:  1px solid var(--text-colour);
    color: var(--text-colour);
}
.current-shifts-option:active{
    border:  1px solid var(--worky-colour);
    color: var(--text-colour);
    background: white;
    color: var(--worky-colour);
}


.top-agency{
    height: auto;
    margin: auto;
    width: calc(100% - 50px);
    /* margin-top: 50px; */
    padding-top: 20px;
    display: flex;
}


.srstatus-container-agency{
    height: 275px;
    width: 100%;
    margin: auto;
    /* margin-left: 10%; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* margin-top: 70px; */
    /* background: red; */
}

.srstatus-right-agency{
    position: absolute;
    z-index: 1;
    right: 20px;
    margin-top: 80px;
    height: 50px;
    width: 50px;
    border: none;
    background: var(--launch-colour);
    border-radius: 100%;
    background-color: white;
    /* box-shadow: 0 0 2px rgba(0,0,0,0.5); */
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    color: var(--worky-colour);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms ease-in-out;
}

.srstatus-right-agency:hover{    
    background: var(--launch-colour);
}
.srstatus-right-agency:active{    
    transform: scale(0.95);
}


.srstatus-title-agency{
    height: 35px;
    width: 100%;
    font-size: 25px;
    text-align: left;
    text-indent: 15px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 800;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    /* justify-content: center; */
}

.calendar-button-agency{
    padding: 7px;
    background: none;
    border: none;
    /* background: var(--launch-colour); */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* margin-left: 10px; */
    color: var(--text-colour);
    border-radius: 5px;
}


.worky-footer-label{
    height: auto;
    width: auto;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    bottom: 22px;
    font-size: 22px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    /* color: var(--launch-highlight); */
    color: #d7d7d7;
    /* background: red; */
    height: 50px;
    display: flex;
    align-items: center;
}
.worky-footer-label-mobile{
    height: auto;
    width: auto;
    /* left: 50%;
    transform: translate(-50%);
    position: absolute;
    bottom: 22px;
    font-size: 22px; */
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    /* color: var(--launch-highlight); */
    color: #d7d7d7;
    /* background: red; */
    /* height: 50px; */
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 10px;
    margin: auto;
}

.worky-contact-agency-button{
    height: 50px;
    width: 50px;
    background: var(--worky-colour);
    position:  fixed;
    bottom: 20px;
    right: 20px;
    border: none;
    border-radius: 40px;
    /* box-shadow: 0 0px 5px 2px rgba(111, 111, 111, 0.1); */
    box-shadow: 0 4px 32px 0 rgba(0,0,0,0.1);
    transition: 0.1s ease-in-out;
    cursor: pointer;
    /* color: white; */
    color: var(--launch-colour);
    z-index: 2;
}
.worky-contact-agency-button:hover{box-shadow: 0 4px 32px 0 rgba(0,0,0,0.1);}
.worky-contact-agency-button:active{background: var(--hightlight-colour); color: white;}

.worky-contact-agency-button-spun{
    height: 50px;
    width: 50px;
    background: var(--worky-colour);
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: none;
    border-radius: 40px;
    box-shadow: 0 4px 32px 0 rgba(0,0,0,0.1);
    transition: 0.1s ease-in-out;
    cursor: pointer;
    /* color: white; */
    color: var(--launch-colour);
    z-index: 2;
    transform: rotate(-45deg);
}
.worky-contact-agency-button-spun:hover{box-shadow: 0 4px 32px 0 rgba(0,0,0,0.1);}
.worky-contact-agency-button-spun:active{background: var(--hightlight-colour); color: white;}

.worky-contact-agency-button-info{
    height: 50px;
    width: 0px;
    background: #d7d7d7;
    position: fixed;
    bottom: 20px;
    right: 45px;
    z-index: 1;
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
    transition: 0.15 ease-in-out;
    display: none;
    
}

#worky-contact-button-active{
    width:auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-right: 50px;
    padding-left: 25px;
    color: var(--text-colour);
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: var(--text-colour);
}











.worky-app-nav-mobile{
    height: 60px;
    /* width: calc(100% - 15px); */
    width: 100%;

    background-color: rgba(255, 255, 255, .55);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;

    position: fixed;
    /* left: 7.5px; */
    bottom: 0px;
    padding-bottom: 7.5px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 18px; */
    transition: 0.15s ease-in-out;
    gap: 5px;
}

#worky-app-nav-mobile-popup-open{
    height: 145px;
    /* width: calc(100% - 15px); */
    width: 100%;
    background-color: rgba(255, 255, 255, .9);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    position: fixed;
    /* left: 7.5px; */
    bottom: 8px;
    z-index: 99;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    align-items:center; 
    /* justify-content: center; */
    transition: 0.15s ease-in-out;
    /* background: blue; */
}

.worky-app-nav-mobile-popup-close-btn{
    height: 5px;
    width: 35%;
    margin-top: 7px;
    margin-bottom: 1px;
    border: none;
    background: var(--launch-colour2);
    border-radius: 3px;
    transition: 0.15s ease-in-out;
}
.worky-app-nav-mobile-popup-close:active{
    background: var(--launch-colour);
}

#worky-app-nav-mobile-popup-closed{
    display: none;
    transition: 0.15s ease-in-out;
}

.worky-app-nav-mobile-popup-open-info-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: var(--text-colour);
    gap: 10px;
    width: 100%;
    /* background: rebeccapurple; */
}

.worky-app-nav-mobile-popup-open-apptray{
    height: 75px;
    width: 100%;
    display: flex;
    margin-top: -10px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* background: green; */
    /* background: red; */
}

.worky-app-nav-mobile-popup-open-app{
    height: 60px;
    width: 65px;
    border: none;
    background: none;
    background: var(--launch-colour2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.worky-contact-agency-button-info-item-btn{
    background: var(--launch-colour2);
    padding: 5px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* padding-left: 15px; */
    /* padding-right: 15px; */
    border-radius: 10px;
    font-weight: 600;
    /* width: calc(50% - 5px); */
    width: 50%;
    /* background: red; */
}

.worky-app-nav-mobile-option{
    height:calc(100% - 10px);
    width:calc((100% / 5) - 6px);
    border: none;
    background: none;
    color: var(--launch-highlight);
    border-radius: 15px;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 9px;
}

.worky-app-nav-mobile-option:active{
    background: var(--launch-colour2);
}

.worky-app-nav-mobile-option-middle{
    height:calc(100% - 10px);
    width:calc((100% / 5) - 24px);
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    background: var(--worky-colour);
    color: white;
    border-radius: 15px;
    font-size: 40px;
    display: flex;
    font-weight: 500;
    align-items:center;
    justify-content: center;

}

#worky-app-nav-mobile-option-active{
    /* color: #7d7d7d; */
    color: var(--worky-colour);
    /* transform: scale(1.11); */
    /* box-shadow: 0 0px 5px 2px rgba(111, 111, 111, 0.1); */
    /* background: var(--launch-colour2); */
}

.worky-app-nav-top-mobile{
    /* height: 70px; */
    height: auto;
    position: sticky;
    padding-top: 2.5px;
    padding-bottom: 1px;
    width: 100%;
    background: var(--launch-colour2);   
    display: flex;
    align-items:center;
    justify-content: space-between;
    border-bottom: 1px solid var(--launch-colour);
}

.worky-app-nav-top-company-holder-mobile{
    height: 100%;
    width: auto;
    /* background: red; */
    /* padding-left: 5vw; */
    padding-left: 4%;
}

.worky-app-nav-top-company-inner-mobile{
    height: 100%;
    font-family: "mendl-sans-dusk", sans-serif;
    /* font-family: 'DM Sans', sans-serif; */
    font-weight: 600;
    font-size: 23px;
    color: var(--text-colour);
    display: flex;
    align-items:center;
    justify-content: center;
    padding-top: 4px;
    /* background: red; */
}

.worky-app-nav-top-icon-holder-mobile{
    height: 100%;
    width: auto;
    /* background: red; */
    padding-right: 4%;
    display: flex;
    align-items:center;
    justify-content: center;
}

.worky-app-nav-top-icon-inner-mobile{
    height: 45px;
    width: 45px;
    border-radius: 40px;
    display: flex;
    align-items:center;
    justify-content: center;
    color: #d7d7d7;
}


.worky-app-header-right-user-account-holder-mobile{
    height: auto;
    width: calc(100% - 20px);
    background: white;
    border-radius: 20px;
    position: absolute;
    z-index: 2;
    top: 55px;
    right: 10px;
    padding-top: 5px;
    padding-bottom: 12.5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-shadow: 0 4px 32px 0 rgba(0,0,0,0.1);
}


.worky-app-header-right-user-account-holder-item-top-mobile{
    font-size: 20px;
    font-weight: 700;
    color: var(--text-colour);
    font-family: 'DM Sans', sans-serif;
    height: 40px;
    display: flex;
    align-items:center;
    justify-content: left;
    flex-direction: row;
    text-align: left;
    width: 85%;
    margin: auto;
    padding: 7.5px;
    border-radius: 15px;
    gap: 15px;
}


.worky-app-header-right-user-account-holder-item-mobile{
    height: 50px;
    width: calc(90% - 15px);
    margin: auto;
    border-radius:10px ;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    gap: 20px;
    padding-left: 15px;
    padding-right: 15px;
    color:var(--launch-highlight);
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    background: var(--launch-colour2);
    color: #7d7d7d;
}

.worky-app-header-right-user-account-holder-item-mobile:active{
    background: var(--launch-colour);
}

.worky-app-nav-mobile-tray-option{
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    color: var(--launch-highlight);
    border-radius: 10px;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 9px;
    background: var(--launch-colour);
    background: white;
    /* border: 1px solid var(--launch-colour2); */
}
.worky-app-nav-mobile-tray-option-active{
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    color: var(--worky-colour);
    border-radius: 10px;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 9px;
    background: var(--launch-colour);
    background: white;
    /* border: 1px solid var(--launch-colour2); */
}




.worky-topshelf-mobile{
    height: auto;
    width: 95%;
    margin: auto;
    margin-top: 2.5vw;
}

.worky-topshelf-intro-mobile{
    height: 50px;
    width: 100%;
    padding-left: 10px;
    padding-top: 0px;
    padding-bottom: 25px;
    font-family: 'DM Sans', sans-serif;
    font-size: 32.5px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.worky-topshelf-greeting-mobile{
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 15px;
}

.worky-topshelf-intro-title-mobile{
    width: 100%;
    margin-top: -3px;
    display: flex;
    font-weight: 700;
    align-items: center;
}


.worky-topshelf-quick-action-holder-mobile{
    height: 80px;
    width: calc(100% - 10px);
    padding-left: 10px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding-top: 5px;
    overflow-x: scroll;
    white-space: nowrap;
}

.worky-topshelf-quick-action{
    height: 98%;
    min-width: 75px;
    
    
    /* background: #d7d7d7; */
    /* background: white; */
    /* background: #d7d7d7; */
    background: none;
    border: none;
    border-radius: 15px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* gap: 13px; */
    flex-direction: column;


    /* box-shadow: 0 0 2px rgba(0,0,0,0.1); */
    white-space: normal;
    background: white;
    /* height: 75px; */
    /* border-radius: 100%; */
}

.quick-action-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 10.5px;
    color: var(--text-colour);
    color: #ababab;
    /* background: red; */
    width: 100%;
    padding-left: 5px;
    display: flex;
    align-items: flex-start;
    padding-bottom: 7.5px;
}

.worky-topshelf-quick-action-inner-icon{
    background: var(--launch-colour);
    background: white;
    padding: 8px;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    /* box-shadow: 0 4px 32px 0 rgba(0,0,0,0.1); */

}

.worky-topshelf-card-title-mobile{
    padding-top: 15px;
    height: 35px;
    /* width: 100%; */
    /* background: green; */
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: var(--text-colour);
}



.worky-topshelf-card-mobile{
    height: 37.5vw;
    width: 100%;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: white;
    /* background: var(--launch-colour); */
}

.worky-topshelf-card-icon-mobile{
    height: 80px;
    width: 80px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 100px; */
    /* color: white; */
}



.worky-topshelf-card-info-mobile{

    width: 60%;
    /* background: red; */
    display: flex;
    margin-top: -5px;    
    align-items: left;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    text-align: left;
}

.worky-topshelf-card-info-title-mobile{
    font-family: "mendl-sans-dusk", sans-serif;
    /* font-family: 'DM Sans', sans-serif; */
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    color: white;
}

.worky-topshelf-card-info-subtitle-mobile{
    font-size: 12px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-style: normal;
    color: white;
}

.worky-topshelf-option-holder{
    display: flex;
    gap: 7px;
    align-items: center;
}

.worky-topshelf-option-holder-mobile{
    /* background: red; */
    padding-left: 10px;
    display: flex;
    gap: 10px;
    height: auto;
    overflow-x: scroll;
}

.top-agency-mobile{
    width: 100%;
    padding-top: 25px;
}


.worky-topshelf-test{
    height: auto;
    width: calc(100% - 15px);
    /* padding-top: 50px; */
    /* background: red; */
    padding-left: 7.5px;
    display: flex;
    /* padding-top: 20px; */
    align-items: center;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
}

.worky-topshelf-button{
    height: 90px;
    width: 100%;
    background: none;
    border: none;
    background: white;
    border-radius:15px ;
    display: flex;
    align-items: flex-start;
    justify-content:space-between;
    gap: 0px;
    flex-direction: column;
}

.worky-topshelf-button-top{
    height: 50px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 10px;
}

.worky-topshelf-button-top-icon{
    height: 45px;
    width: 45px;
    /* background: var(--launch-colour2); */
    /* background: rgba(255, 255, 255, 0.8); */
    border-radius: 40px;
    display: flex;
    align-items: center;
    margin-top: 7.5px;
    justify-content: center;
    font-size: 35px;
    /* color:var(--worky-colour); */
    color: white;
    margin-left: 5px;
    /* border: 2px solid white; */
}

.worky-topshelf-button-top-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;     
    margin-bottom: 15px;
    margin-left: 15px;
    width: calc(100% - 15px);
    text-align: left;
}
.worky-topshelf-button-bottom{
    height: auto;
    max-height: 35px;
    /* background: red; */
}
.worky-topshelf-button-top-subtitle{
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    text-align: left;
    color: white;
    padding-left: 50px;
    /* padding-right: 10px; */
}
























.worky-shift-details-v2{
    height: auto;
    max-height: 60vh;
    width: 75vw;


    max-width: 850px;
    min-width: 820px;
    padding: 20px;
    background: white;
    border-radius: 25px;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    box-shadow: 0 4px 32px 0 rgba(0,0,0,0.1);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* gap: 15px; */
    flex-direction: column;
}
.worky-shift-details-v2-header{
    height: 90px;
    width: 100%;
    /* background: var(--launch-colour2); */
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    
}

.worky-shift-details-v2-header-close{
    /* height: 90px; */
    height: 100%;
    width: 90px;
    background: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content:center;
    border-radius: 20px;
    /* color: var(--launch-highlight); */
    color: #7d7d7d;
}
.worky-shift-details-v2-header-close-mobile{
    /* height: 90px; */
    height: 90px;
    width: 90px;
    background: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content:center;
    border-radius: 20px;
    /* color: var(--launch-highlight); */
    color: #7d7d7d;
    /* background: red; */
    margin-top: -5px;
    margin-right: -10px;
    float: right;
}

.worky-shift-details-v2-header-close:hover{
    color: var(--text-colour);
    /* transform: scale(1.1); */
}
.worky-shift-details-v2-header-close:active{
    color: var(--worky-colour);
}

.worky-shift-details-v2-header-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 38px;
    color: var(--text-colour);
    padding-left: 10px;
    /* padding-right: 40px; */
    width: 215px;
    text-align: left;
    /* background: red; */
    /* background: blue; */
}
.worky-shift-details-v2-header-title-mobile{
    padding-left: 5px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 38px;
    color: var(--text-colour);
}

.worky-shift-details-v2-header-holder{
    margin-right: 15px;
    height: 100px;
    /* border-radius: 20px; */
    width: 100%;
    /* background: red; */
    /* background: var(--launch-colour2); */
    /* width: auto; */
    /* width: 70%; */
    display: flex;
    margin-top: -10px;
    align-items: center;
    justify-content: space-between;
    /* background: white; */
    /* border-radius: 10px; */
}

.worky-shift-details-v2-header-info-holder{
    height: 50px;
    width: 100%;
    /* background: green; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 5px;
    margin-top: -10px;
    padding-left: 5px;
    /* padding-bottom: 5px; */
}

.worky-shift-details-v2-header-info{
    height: 35px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    background: var(--launch-colour2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 17px;
    color: #7d7d7d;
    color: var(--text-colour);
}







.worky-shift-details-v2-main{
    height: auto;
    /* height: auto; */
    max-height: 475px;
    width: 100%;
    border-radius: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: flex-start;
    justify-content:flex-start;
    flex-direction: column;
    overflow: hidden;
    gap: 10px;
    background: var(--launch-colour2);
    /* background: blue; */
}

.worky-shift-details-v2-main-left{
    height: calc(100% - 20px);
    width: 100%;
    padding:10px;
    border-radius: 20px;
    background: var(--launch-colour2);
    /* background: blue; */
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.worky-shift-details-v2-main-right{
    height: auto;
    /* max-height: calc(100% - 7.5px); */
    height: calc(100% - 20px);
    width: 35%;
    background: var(--launch-colour2);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
    padding-top:10px;
    padding-bottom: 10px;
}

.worky-shift-details-v2-main-right-info-card{
    height: 75px;
    width: calc(100% - 15px);
    background-color: white;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content:flex-start;
}

.worky-shift-details-v2-main-right-info-card-icon{
    height: 47.5px;
    width: 47.5px;
    background: linear-gradient(90deg, #73a3f7, #4563be);
    border-radius: 40px;
    margin-left: 15px;
    opacity: 0.95;
}

.worky-shift-details-v2-main-right-info-card-title{
    height: 50px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    margin-left: 20px;
    color: var(--text-colour);
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content:center;
}

.worky-shift-details-v2-main-right-info-card-value{
    width: calc(100% - 155px);
    /* background: blue; */
    text-align: right;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
}

.worky-shift-details-v2-main-card-holder{
    display: flex;
    align-items: center;
    justify-content:flex-start;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    overflow-x: visible;
    white-space: nowrap;
    /* background: red; */
    /* padding-top: 10px; */
    padding-bottom: 10px;
    height: auto;
    border-radius: 15px;
    width: calc(100% - 20px);
    margin: auto;
}
.worky-shift-details-card-expanded{
    height: 140px;
    width: 100%;
    background: var(--launch-colour2);
    background: white;
    border-radius: 15px;
    /* background: red; */
    border: none;
    transition: 0.1s ease-in-out;
}
.worky-shift-details-card-expanded-mobile{
    height: 220px;
    width: 100%;
    background: var(--launch-colour2);
    background: white;
    border-radius: 15px;
    /* background: red; */
    border: none;
    transition: 0.1s ease-in-out;
}
.worky-shift-details-card{
    min-height: 60px;
    height: auto;
    width: 100%;
    background: var(--launch-colour2);
    background: white;
    border-radius: 15px;
    /* background: red; */
    border: none;
    /* cursor: pointer; */
    transition: 0.1s ease-in-out;
}

.worky-shift-details-card:hover{
    box-shadow: 0 4px 32px 0 rgba(0,0,0,0.1);
}
.worky-shift-details-card-expanded:hover{
    box-shadow: 0 4px 32px 0 rgba(0,0,0,0.1);
}

.worky-shift-details-card-top{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: red; */
}

.worky-shift-details-card-bottom{
    /* height: 15px; */
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.worky-shift-details-card-bottom-expand-btn{
    height: 35px;
    width: 35px;
    border-radius: 100%;
    background: none;
    font-size: 11px;
    border: none;
    cursor: pointer;
    /* background: blue; */
    color: var(--launch-highlight);
    margin-right: 15px;
    background: var(--launch-colour2);
}
.worky-shift-details-card-bottom-expand-btn:hover{color: var(--text-colour);}
.worky-shift-details-card-bottom-expand-btn:active{color: var(--worky-colour);}

.worky-shift-details-card-bottom-expanded{
    height: 70px;
    width: 97.5%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background: red; */
    border-top: 1px solid var(--launch-colour2);
    /* padding-top: 10px; */
}
.worky-shift-details-card-bottom-expanded-mobile{
    height: 150px;
    display: flex;
    /* background: red; */
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.worky-shift-details-card-bottom-expanded-inner{
    height: 60px;
    width: 97.5%;
    margin: auto;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates 3 equal columns */
    gap: 2.5px; /* Adjust the gap between columns as needed */
    /* background: red; */
    
}

.worky-shift-details-card-bottom-expanded-inner-mobile{
    height: 170px;
    width: 95%;
    margin: auto;
    border-top: 1px solid var(--launch-colour2);
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 1fr); /* Creates 3 equal columns */
    gap: 2.5px; /* Adjust the gap between columns as needed */
    /* background: red; */
    padding-top: 10px;
    padding-left: 5px;
}

.worky-shift-details-card-bottom-expanded-info-holder{
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    gap: 10px;
    color: var(--text-colour);
    font-size: 12px;
}
.worky-shift-details-card-bottom-expanded-info-title{
    font-weight: 500;
}
.worky-shift-details-card-bottom-expanded-info-value{
    font-weight: 700;
    display: flex;
    align-items: center;
}


.worky-shift-details-v2-main-left-title-holder{
    min-height: 55px;
    padding-top: 10px;
    max-height: 55px;
    width: 100%;
    /* background-color: green;    */
    display: flex;
    align-items: center;
    justify-content:space-between ;
}

.worky-shift-details-v2-main-left-title{
    padding-left: 30px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    color: var(--text-colour);
}
.worky-shift-details-v2-main-left-title-mobile{
    padding-left: 30px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    color: var(--text-colour);
}

.worky-shift-details-v2-main-left-filter{
    margin-right: 30px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    color: var(--text-colour);
    height: 35px;
    width: auto;
    border-radius: 30px;
    background: var(--launch-colour2);
    border:1px solid var(--launch-highlight);
    padding-left: 20px;
    width: 130px;
    color: #7d7d7d;
}

.worky-shift-details-v2-main-right-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    color: var(--text-colour);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content:center ;
}

.worky-shift-details-card-top-btn-holder{
    display: flex;
    align-items: center;
    justify-content:center ;
    gap: 7.5px;
    height: 100%;
    /* background: red; */
}
.worky-shift-details-card-action{
    height: 40px;
    width: 40px;
    background:var(--launch-colour2);
    /* background: none; */
    border: none;
    border-radius: 15px;
    cursor: pointer;
    color: var(--launch-highlight);
    /* color: var(--launch-colour); */
    display: flex;
    align-items: center;
    justify-content:center ;
    font-size: 40px;
    /* border: 2px solid var(--launch-colour2); */
}

.worky-shift-details-card-action:hover{
    background:var(--launch-colour);
    color:#7d7d7d;
}

.worky-shift-details-card-top-shift-details-holder{
    height: 100%;
    /* width: calc(100% - 180px); */
    padding-left: 10px;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content:flex-start ;
    gap: 15px;
}
.worky-shift-details-card-top-shift-details-holder-mobile{
    height: 100%;
    width: 100%;
    /* padding-left: 12.5px; */
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content:space-between ;
}


.worky-shift-details-card-profile{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    gap: 10px;
    padding-left: 10px;
    padding-right: 12.5px;
    cursor: pointer;
}

.worky-shift-details-card-profile:hover{
    background: var(--launch-colour2);
}

.worky-shift-details-card-profile-unassigned{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    gap: 10px;
    padding-left: 25px;
    padding-right: 25px;
    background: var(--launch-colour2);
    animation: AssigningAnimation 2s linear infinite;
}
.worky-shift-details-card-profile-unassigned-mobile{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    gap: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    background: var(--launch-colour2);
    animation: AssigningAnimation 2s linear infinite;
}

@keyframes AssigningAnimation {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

.worky-shift-details-card-worker-icon{
    height: 32.5px;
    width: 32.5px;
    background: var(--launch-colour);
    border-radius: 20px;
}

.worky-shift-details-card-worker-name{
    height: 40px;
    width: auto;
    max-width: 200px;
    /* padding-left: 25px; */
    /* background: var(--launch-colour2); */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    color: var(--text-colour);
    /* background: red; */
    white-space: nowrap;
}

.worky-shift-details-card-worker-checked-holder{
    height: 40px;
    width: auto;
    min-width: 85px;
    padding-left: 25px;
    padding-right: 25px;
    background: var(--launch-colour2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /* background: red; */
}

.worky-shift-details-card-worker-checked-holder-mobile{
    height: 42.5px;
    width: auto;
    min-width: calc(100% - 220px);
    /* padding-left: 25px; */
    /* padding-right: 25px; */
    background: var(--launch-colour2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* background: red; */
}

.checked-holder-inner-mobile{
    height: 100%;
    width: 50%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.checked-holder-inner-title-mobile{
    height: 10px;
    width: 100%;
    /* background: green; */
    display: flex;
    align-items: center;
    justify-content:center;
    /* justify-content:flex-start; */
    /* padding-left: 10px; */
    flex-direction: row;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 10px;
    /* color: #7d7d7d; */
    color: var(--launch-highlight);
}

.checked-holder-inner-value-mobile{
    height: 22px;
    width: 100%;
    /* background: blue; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size:16px;
    color: #7d7d7d;
}

.checked-holder-inner-line-mobile{
    height: 70%;
    width: 1px;
    background: #d7d7d7;
}













.worky-shift-details-card-worker-checked-inner-title-mobile{
    height: 15px;
    width: 100%;
    background: blue;

    display: flex;
    align-items:flex-start;
    justify-content: flex-start;
    flex-direction: row;

    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
}
.worky-shift-details-card-worker-checked-inner-title-mobile{
    height: 27.5px;
    width: 100%;
    background: green;

    display: flex;
    align-items:flex-start;
    justify-content: flex-start;
    flex-direction: row;

    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
}

.worky-shift-details-card-worker-checked-inner-holder-mobile{
    height: 100%;
    background: red;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.worky-shift-details-card-worker-checked-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size:9px;
    color: #7d7d7d;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -22px;
    height: 10px;
    padding-top: 2.5px;
    /* background: red; */
}
.worky-shift-details-card-worker-checked-value{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size:19px;
    color: #7d7d7d;
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    height: 30px;
    /* background: blue; */
}


.worky-shift-details-card-worker-status-tag-holder{
    height: 100%;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2.5px;
    /* background: red; */
}

.worky-shift-details-card-worker-status-tag{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size:8.5px;
    color: #7d7d7d;
    background: var(--launch-colour2);
    border-radius: 4px;
    padding:0px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    height: 11px;
}

#card-status-tag-ontime{
    background: rgb(123, 220, 123);
    color: white;
}
#card-status-tag-late{
    background:orange;
    color: white;
}



.worky-shift-details-card-top-shift-date-holder{
    height: 45px;

    width: 100%;
    background: var(--launch-colour2);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content:center ;
    color: var(--text-colour);
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-style: normal;
    /* font-size: 20px; */
}

.worky-shift-details-card-top-shift-time-holder{
    height: 50px;
    width: 150px;
    background: white;
}



.worky-shift-details-v2-mobile{
    height: 100vh;
    width: 100vw;
    background: white;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
}

.worky-shift-details-v2-header-holder-mobile{
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    width: 95%;
    margin: auto;
}

.worky-shift-details-v2-header-info-holder-mobile {
    height: auto;
    max-width: 100%;
    /* background: red;  */
    padding-left: 2.5vw;
    /* padding-bottom: 10px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
}

.worky-shift-details-v2-header-info-mobile{
    height: 40px;
    /* width: 300px; */
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    background: var(--launch-colour2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #7d7d7d;
}


/* height: 35px;
width: auto;
padding-left: 20px;
padding-right: 20px;
background: var(--launch-colour2);
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
font-family: 'DM Sans', sans-serif;
font-weight: 600;
font-style: normal;
font-size: 17px;
color: #7d7d7d;
color: var(--text-colour); */


/* 
.worky-shift-details-v2-shift-holder-mobile{
    margin: auto;
    margin-top:20px;
    width: 95%;
    border-radius: 15px;
    height: auto;
    overflow-y: scroll;
    white-space: nowrap;
    padding-bottom: 10px;
    background: var(--launch-colour2);
}

.worky-shift-details-v2-shift-holder-title-mobile{
    height: 70px;
    width: 100%;

    display: flex;
    align-items: center;

    justify-content:space-between ;
}
.worky-shift-details-v2-shift-container-mobile{
    width: 95%;

    height: auto;
    margin: auto;
    display: flex;
    gap: 10px;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: visible;
    white-space: nowrap;
} */

.worky-shift-details-v2-shift-holder-mobile {
    margin: auto;
    margin-top: 20px;
    width: 95%;
    border-radius: 15px;
    height: auto;
    padding-bottom: 10px;
    background: var(--launch-colour2);
}

.worky-shift-details-v2-shift-holder-title-mobile {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.worky-shift-details-v2-shift-container-mobile {
    width: 95%;
    height: auto;
    max-height: calc(100vh - 245px);
    margin: auto;
    display: flex;
    gap: 10px;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.worky-shift-details-card-worker-name-mobile{
    height: 40px;
    width: auto;
    max-width: 120px;
    /* padding-left: 25px; */
    /* padding-right: 25px; */
    /* background: var(--launch-colour2); */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 19px;
    color: #7d7d7d;
    overflow-y: scroll;
    /* background: red; */
    white-space: nowrap;
}


