:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}

.jobber-profile-container{
    height: auto;
    width: 90%;
    /* background: red; */
    margin: auto;
    /* margin-top: 100px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    background: white;
    border-radius: 20px;
    border: 1px solid var(--launch-colour);
}

.jobber-profile-profile-pic{
    height: 90px;
    width: 90px;
    border-radius: 120px;
    background: var(--launch-colour);
}

.jobber-profile-name{
    width: 100%;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    color: var(--text-colour);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
}

.jobber-info-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.jobber-profile-email{
    width: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    padding-left: 12.5px;
    padding-right: 12.5px;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    border-radius: 7.5px;
    color: var(--text-colour);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 280px;
    background: var(--launch-colour2);
}

.jobber-profile-phone{
    width: 100%;
    margin-top: 1px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: var(--text-colour);
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: white; */
}

.jobber-detail-container{
    height: auto;
    width: 90%;
    /* background: red; */
    margin: auto;
    /* margin-top: 100px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7.5px;
    padding-bottom: 20px;
    padding-top: 20px;
    background: white;
    border-radius: 20px;
    border: 1px solid var(--launch-colour);
}

.jobber-detail{
    height: 45px;
    width: 90%;
    background: var(--launch-colour2);
    border-radius: 15px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.jobber-detail-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    margin-left: 10px;
    color: var(--text-colour);
    display: flex;
    align-items: center;
}

.jobber-detail-value{
    width: auto;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: var(--text-colour);
    display: flex;
    align-items: center;
    margin-right: 10px;
    /* background: red; */
}

#address-value{
    max-width: 200px;
    text-align: right;
}





.report-issue{
    margin-top: 10px;
    height: 20px;
    background: none ;
    border: none;
    font-weight: 400;
    font-style: normal;
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    margin-bottom: 10px;
    color: var(--text-colour);
}



.jobber-profile-logout{
    height: 45px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    background: var(--worky-colour);
    border-radius: 12.5px;
    margin:auto;
    color: white;
    display: flex;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    align-items: center;
    margin-bottom: 80px;
}