:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}





.my-shifts-option{
    height: 27.5px;
    padding-left:10px;
    padding-right:10px;
    border-radius: 12px;
    border: 1px solid var(--text-colour);
    color: var(--text-colour);
    background: none;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    font-size: 13px;
}


.my-shifts-option:hover{
    border: 1px solid var(--worky-colour);
    /* background: white; */
    color: var(--worky-colour);
}

#my-shifts-option-active{
    border: 1px solid var(--worky-colour);
    background: var(--worky-colour);
    color: white;
    font-weight: 600;
}

.worky-carousel-container-v2{
    height: auto;
    margin: auto;
    width: calc(100% - 50px);
    
    /* margin-top: 50px; */
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.worky-carousel-v2 {
    height: 185px;
    width: 100%;
    /* background: blue; */
    gap: 10px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
  }

.worky-carousel-v2-inner{
    width: calc(100% - 60px);
    padding-top: 10px;
    /* background: green; */
    display: flex;
    overflow-x: scroll;
    gap: 20px;
    scroll-behavior: smooth;
    /* Hide scrollbar */
    -webkit-overflow-scrolling: touch; 
    scrollbar-width: none;
    -ms-overflow-style: none; 
    scroll-snap-type: x mandatory;
    
    &::-webkit-scrollbar { display: none;}
  
}


.carousel-card-v2 {
    height: 175px;
    width: 225px;
    flex-shrink: 0; /* Prevent cards from shrinking during scrolling */
    scroll-snap-align: start;
    background: white;
    border-radius: 22.5px;
    transition: 200ms ease-in-out;
    border: 4px solid white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.carousel-card-v2:hover {
    border: 4px solid var(--launch-colour);
}

#carousel-card-v2-assigned:hover{
    border: 4px solid #b2df8f;
}



.carousel-card-v2-date{
    font-family: 'DM Sans', sans-serif;
    font-weight: 800;
    font-size: 25px;
    color: var(--text-colour);
}



.carousel-card-v2-time{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #7d7d7d;
    padding-top: 2.5px;
}

.carousel-card-v2-ratio{
    height: 50px;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    background: var(--launch-colour2);
    margin-top: 15px;
    border-radius: 15px;
    min-width: 50px;

    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 20px;

    color: #7d7d7d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}