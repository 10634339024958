

.loading-page{
    height: 100vh;
    width: 100vw;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    perspective: 1000px; /* Ensures the 3D effect is visible */

}


@keyframes spinAndPause3D {
    0% {
        transform: rotate3d(0, 0, 1, 0deg); /* Neutral start */
    }
    55% {
        transform: rotate3d(0, 1, 0, 360deg);
        animation-timing-function: ease-in-out; /* Smooth slow down */
    }
    100% {
        transform: rotate3d(0, 0, 1, 0deg); /* Back to neutral */
    }
}


.loading-page-w {
    height: 75px;
    width: 75px;
    border-radius: 100%;
    background: var(--worky-colour);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spinAndPause3D 2s infinite;
    transform-origin: center; 
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-size: 21.5px;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);

}