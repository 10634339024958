:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
html{
    background: var(--launch-colour2);
}

.mobile-app{
    height: 100%;
    width: 100vw;
    background: var(--launch-colour2);
}
/* top-left | top-right | bottom-right | bottom-left */


/* mobile nav start */
.mobile-nav{
    height: 25px;
    width: 100%;
    display: flex;
    background: white;
    position: absolute;
    /* margin-top: -50px; */
    z-index: 1000;
}

.mobile-nav-left{
    height: 55px;
    width: 15%;
    background: var(--launch-colour2);
    border-radius: 0 30px 0 0;
}

.mobile-nav-middle{
    height: 55px;
    width: 70%;
    background: white;
    border-radius: 0 0 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    color: var(--worky-colour); 
    transition: 0.5s ease-in-out;
        /* background: red; */
        z-index: 1000;

}

.mobile-nav-middle.expanded {
    height: 175px;
    padding-top: 10px;

}

.mobile-nav-dropdown{
    height: 115px;
    /* background: red; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.75px;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}

.dropdown-option{
    height: 28px;
    
    width: 80%;
    background: none;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: var(--text-colour);
    border: none;
}

.dropdown-option-selected{
    height: 28px;
    width: 80%;
    background: none;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    color: var(--text-colour);
    border: none;
}

.mobile-nav-middle-top{
    height: 40px;
    width: 100%;
    display: flex;
    justify-content:center;
    align-items:flex-end;
    padding-bottom: 3px;
}

.mobile-nav-middle-bottom{
    height: 15px;
    width: 50px;
    background-image: url('/public/chevrondown.png');
    background-position: center;
    background-position-y: 2px;
    background-repeat: no-repeat;
    background-size: 25px;
}

.mobile-nav-middle-bottom.expanded{
    transform: rotate(180deg);
    margin-bottom:3px ;
}

.mobile-nav-right{
    height: 55px;
    width: 15%;
    background: var(--launch-colour2);
    border-radius: 30px 0 0 0;
}

#jobber-modal{
    width: 100%;
}

#modal-title3{padding-top: 35px;}
/* mobile nav finish */


.lang-selection2{
    height: 30px;
    /* margin-top: -10px; */
    padding-bottom: 20px;
}


.important-docs-container-inner{
    height:auto;
    max-width: 400px;
    min-width: 300px;
    background: white;
    padding-top: 25px;
    padding-bottom: 12.5px;
    border-radius: 15px;
    margin: auto;
    border: 1px solid var(--launch-colour);
}



.important-docs-container{
    height: 500px;
    width: calc(100% - 40px);
    /* padding-top: 100px; */
    margin: auto;
    /* background: white; */
}








/* shifts today start */
.mobile-section-title{
    padding-top: 100px;
    margin-bottom: 7px;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 22px;
    text-align: left;
    color: var(--text-colour);
    z-index: 1;

}

.shifts-container{
    height: auto;
    width: 90%;
    margin-left: 5%;
    gap: 10px;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    /* overflow-y: scroll; */
    /* flex-wrap: nowrap; */
    align-items: center;
    /* padding-left: 1px; */
    /* background: red; */
}

.jobber-shift-block{
    height: 70px;
    width: 100%;
    background: white;
    border: none;
    border-radius: 20px;
    display: inline-block; 
    flex: 0 0 auto;
    transition: 0.1s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
}


.jobber-shift-block-date{
    /* height: 35px; */
    /* width: 90px; */
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    font-size: 25px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);

}

.jobber-shift-block-time{
    /* height: 30px; */
    /* width: 140px; */
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;

}



.jobber-shift-block-total{
    height: 50px;
    width: 85px;
    border-radius: 15px;
    background: var(--launch-colour2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
}



.prev-shifts-container{
    height: 500px;
    width: 90%;
    margin-left: 5%;
    gap: 15px;
    display: flex;
    overflow-x: scroll;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-left: 1px;
    padding-top: 10px;
    border-radius: 35px;
    /* background: red; */
}

.prev-jobber-shift-block{
    height: 155px;
    width: 160px;
    background: white;
    border: none;
    border-radius: 35px;
    display: inline-block; 
    flex: 0 0 auto;
    transition: 0.1s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
}









.shiftblock-modal{
    height: auto;
    width: 90%;
    max-width: 435px;
    border-radius: 30px;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 25px;
}

.shiftblock-date{
    height: 30px;
    width: 100%;
    padding-top: 20px;
    font-size: 28px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.shiftblock-time{
    height: 30px;
    width: 100%;
    font-size: 18px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
}




.shiftblock-modal-title{
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 20px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
}
.shiftblock-modal-details{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.shiftblock-modal-detail{
    height: 45px;
    width: 80%;
    padding-right: 20px;
    padding-left: 20px;
    background: var(--launch-colour2);
    border-radius: 15px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: var(--text-colour);
}
#modal-detail-heading{
    font-weight: 400;

}
.shiftblock-modal-actions{
    height: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
}


#modal-action-add:hover{
    background:#4f9eff;
    border: 2px solid #4f9eff;
    color:white;
    font-weight: 500;
}
#modal-action-extend:hover{
    background:#4f9eff;
    border: 2px solid #4f9eff;
    color:white;
    font-weight: 500;
}
#modal-action-report:hover{
    border: 2px solid #ff8282;
    background: #ff8282;
    color:white;
    font-weight: 500;
}
#modal-action-cancel:hover{
    background:var(--launch-colour);
    border: 2px solid var(--launch-colour);
    font-weight: 500;
}








.first-login-container{
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
    background: var(--worky-colour);
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
}

.first-login-worky{
    height: 20vh;
    width: 300px;
    /* background: grey; */
    font-size: 55px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: white;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 50px; */
}

.first-login-info-holder{
    width: 100%;
    height: 75vh;
    background: white;
    border-radius: 30px 30px 0px 0px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);

}




















.first-login-title{
    height: 50px;
    width: 75%;
    margin: auto;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 28px;
    /* background: red; */
    margin-top: 50px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    color: var(--text-colour);
}



.page-container{
    height: 320px;
    width: 100%;
}

.page-title{
    height: 25px;
    width: 75%;
    /* background: blue; */
    margin: auto;
    text-align: left;
    background: white;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 18px;
}

.page-subtitle{
    /* height: 40px; */
    padding-top: 3px;
    padding-bottom: 17px;
    margin: auto;
    text-align: left;
    width: 75%;
    color: var(--launch-highlight);
    font-size: 13px;
    display: flex;
    align-items: center;
    /* background: red; */
    /* margin-bottom: 5px; */
}

.whimis-download-container{
    max-width: 450px;

    margin: auto;
    /* margin-top: 40px; */
    height: 50px;
    width: 90%;
    border: none;
    border-radius: 15px;
    background: var(--launch-colour2);
    /* border: 1px solid var(--launch-colour); */
    box-shadow: 0 0 2px rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 12.5px;
}

.document-icon{
    height: 40px;
    width: 40px;
    background: var(--launch-colour);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lang-selection{
    height: 30px;
    margin-top: -10px;
}
.document-info{
    height: 100%;
    font-family: 'DM Sans', sans-serif;
    width: 190px;
    font-size: 12px;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--dark-text);
}

.download-icon{
    height: 30px;
    width: 30px;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.whimis-checkbox-container{
    height: auto;
    width: 84%;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    left: 5%;
    bottom: 100px;
    box-shadow: 0 0 2px rgba(0,0,0,0.2);
}

.whimis-confirmation-text{
    font-size: 10px;
    color: var(--text-colour);
}

.file-upload-holder{
    height: 140px;
    width: 80%;
    /* background: red; */
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center ;
    gap: 20px;
}
.file-upload-container{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 140px;
    margin: auto;
    width: 80%;
    max-width: 500px;
    border: none;
    border-radius: 15px;
    background: none;
    border: 2px dashed var(--launch-colour);
    margin-bottom: 20px;
}

.file-upload-container1{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 5px; */
    height: 90px;
    margin: auto;
    width: 80%;
    max-width: 500px;
    border: none;
    border-radius: 15px;
    background: none;
    border: 2px dashed var(--launch-colour);
    margin-bottom: 10px;
}

.file-upload-title{
    color: var(--launch-highlight);
    font-size: 14px;
}

.filename{
    color: var(--launch-highlight);
    font-size: 14px;
    /* position: absolute; */
    width: 100%;
    padding-top: 15px;
}
.fileuploadicon{
    /* position: absolute; */
    width: 100%;
    padding-bottom: 10px;

}




.direct-deposit-container{
    height: 225px;
    width: 75%;
    /* background: red; */
    margin: auto;
}

.direct-deposit-title{
    height: 25px;
    width:100%;
    /* background: grey; */
    color: var(--text-colour);
    font-size: 13px;
    display: flex;
    align-items: center;
}

.direct-deposit-info{
    height: 35px;
    width:75%;
    margin-bottom: 10px;
    display: flex;
    border: 1px solid var(--launch-highlight);
    border-radius: 10px;
    text-indent: 15px;
}

.finished-page-container{
    height: 230px;
    width: 75%;
    margin: auto;
    /* margin-top: -20px; */
    margin-top: 45px;
    background-image: url('/public/coin-walk.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    /* background: red; */
}




.first-login-page-container{
    position: absolute;
    height: 70px;
    width: 100%;
    bottom: 25px;
    display: flex;
    justify-content:space-evenly;
    align-items: center;

}

.first-login-page-left{
    height: 60px;
    width: 60px;
    background: var(--launch-colour);
    border: none;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.first-login-page-left:disabled{
    height: 60px;
    width: 60px;
    /* background: var(--launch-colour2); */
    background: #fbfbfb;
    color: var(--launch-colour2);
    border: none;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.first-login-page-right:disabled{
    height: 60px;
    width: 60px;
    /* background: var(--launch-colour2); */
    background: #fbfbfb;
    color: var(--launch-colour2);
    border: none;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.first-login-page-right:enabled:hover{
    height: 60px;
    width: 60px;
    background: var(--launch-highlight);    
    color: var(--launch-colour2);
    border: none;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.first-login-pages{
    height: 60px;
    width: 130px;
    /* background: var(--launch-colour); */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.dot{
    height: 7px;
    width: 7px;
    border-radius: 10px;
    background: var(--launch-colour);
    transition: 0.2s ease-in-out;
}
#dot-active{
    height: 7px;
    width: 14px;
    border-radius: 10px;
    background: var(--launch-highlight);
}

.first-login-page-right{
    height: 60px;
    width: 60px;
    background: var(--launch-colour);
    border: none;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.first-login-page-last{
    height: 60px;
    width: 220px;
    border: none;
    border-radius: 60px;
    background: var(--worky-colour);
    color: white;
    font-size: 15px;
    font-weight: 500;
}


.empty-shifts-container{    
    height: auto;
    width: calc(100% - 50px);
    /* background-color: red; */
    
    margin: auto;
}

.empty-shifts{
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-evenly;
    padding-top: 20px;
    padding-bottom: 20px;
}

.empty-shifts-logo{
    font-size: 55px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--worky-colour);
    /* background: red; */
    width: 100%;
    height: auto;
}

.empty-shifts-holder{
    height: 200px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.empty-shifts-title{
    font-size: 25px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    /* background: red; */
    width: 100%;
    height: auto;
}



.empty-shifts-text{
    font-size: 12px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    color: var(--text-colour);
    width: 80%;
    height: auto;
}


.empty-shifts-button{
    height: 45px;
    padding-left: 25px;
    padding-right: 25px;
    background: var(--worky-colour);
    color: white;
    font-size: 17px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-style: normal;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}

.empty-shifts-button:hover{
    background: var(--hightlight-colour);
}


.document-verification{
    background-image: url('/public/document4.gif');
    height: 220px;
    width: 220px;
    background-size:contain ;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
    margin-top: 100px;
}







.howworkyworks{
    height: 50px;
    width: 85%;
    padding-top: 70px;
    margin: auto;
}

.howworkyworks-button{
    background: var(--launch-colour);
    height: 30px;
    width: 180px;
    color: var(--text-colour);
    font-size: 14px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight:500;
    font-style: normal;
    border: none;
    border-radius: 10px;
    border: 1px solid var(--launch-highlight);
}

.howworkyworks-button:hover{
    background: var(--worky-colour);
    color:white;
}








.jobber-mobile-nav-v2{
    height: 60px;
    width: 100vw;
    position: fixed;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5px;
    background: white;
    border-top: 1px solid var(--launch-colour);
}

.jobber-mobile-nav-v2-btn-holder{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 700px;
}
#jobber-mobile-nav-v2-btn-active{
    color: var(--worky-colour);
}
.jobber-mobile-nav-v2-btn{
    height: 100%;
    width: 100%;
    background: none;
    border: none;
    color: var(--launch-highlight);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 9px;
}

.modal-contactv2{
    height: auto;
    width: 100%;
    background: white;
    position: absolute;
    bottom: 0px;
    padding-top: 10px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    flex-direction: column;
}


.jobber-modal-close-mobile{
    background: var(--launch-colour);
    width: 120px;
    height: 5px;
    border-radius: 3px;
    border: none;

    margin: auto;
    margin-bottom: 20px;
}

.jobber-modal-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: var(--text-colour);
    margin-bottom: 10px;
}
.jobber-modal-subtitle{
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: var(--text-colour);
    margin-bottom: 10px;
    width: 80%;
    margin: auto;
}


.jobber-input-modal-holder{
    /* background: red; */
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 40px);
    margin: auto;
}

.jobber-modal-input-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 10px;
    width: 100%;
    display: flex;
    color: var(--text-colour);
    text-indent: 10px;
    justify-content: flex-start;
    align-items: center;
}

.jobber-modal-email{
    height: 38px;
    width: calc(100% - 8px);
    border-radius: 10px;
    border: 2px solid var(--launch-colour);
    text-indent: 20px;

}

.jobber-modal-submit{
    height: 45px;
    width: 100%;
    border-radius: 10px;
    background: var(--worky-colour);
    color: white;
    border: none;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
}
.jobber-modal-message{
    min-height: 40px;
    max-height: 101px;
    height: 101px;
    width: calc(100% - 44px);
    border-radius: 15px;
    border: 2px solid var(--launch-colour);

    padding: 20px;
    min-width: calc(100% - 44px);
    max-width: calc(100% - 44px);

}

.jobber-geo-holder{
    
    margin-top: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.jobber-clockin-button{
    height:42.5px;
    width: calc(100% - 40px);
    color: white;
    border: none;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    border-radius: 15px;
    background: var(--worky-colour);
}



.jobber-greeting{
    height: auto;
    padding-top: 25px;

    width: calc(100% - 40px);
    margin:auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.jobber-greeting-icon{
    color: #7d7d7d;
}

.jobber-greeting-text-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.jobber-greeting-text-title{
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #7d7d7d;
    text-align: left;
    width: 100%;
}
.jobber-greeting-text-name{
    font-family: 'DM Sans', sans-serif;
    font-size: 22px;
    text-align: left;
    width: 100%;
    font-weight: 700;
    color: var(--text-colour);
}


.jobber-clock-location-holder{
    height: auto;
    width: calc(100% - 40px);
    margin: auto;
    background: white;
    padding-top: 15px;
    padding-bottom: 10px;
    margin-top: 15px;
    border-radius:20px ;
}

.jobber-clock-location-resp{
    width: 100%;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #7d7d7d;
}

.jobber-clock-map-holder{
    height: 125px;
    width: calc(100% - 20px);
    /* background: var(--launch-colour2); */
    overflow: hidden;
    margin: auto;
    border-radius: 15px;
    margin-top:15px;
}

.jobber-map-pin {
    height: 17px;
    width: 17px;
    border-radius: 100%;
    background: #007aff;
    border: 4px solid white;
}



.pending-sr-container{
    /* height: auto; */
    width: calc(100% - 40px);
    /* background: red; */
    overflow: hidden;
    margin: auto;
}

.pending-sr-title{
    /* width: 100%; */
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: var(--text-colour);
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    justify-content: space-between;
}

.pending-shifts-accept-all{
    height: 20px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #007aff;
    border-radius: 7.5px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
}

.pending-sr-holder {
    width: 100%;
    max-height: 405px;
    padding-top: 5px;
    display: flex;
    align-items: flex-start; /* Align items to the top */
    flex-direction: column;
    gap: 7.5px;
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}


.pending-sr-holder::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.pending-sr-unit {
    height: auto;
    width: calc(100% - 2px);
    border: 1px solid var(--launch-colour);
    background: white;
    border-radius: 10px;
    flex-shrink: 0;
}

.pending-sr-accordion-summary{
    height: 45px;
    width: 100%;
    /* padding-left:10px; */
    /* padding-right: 10px; */
    background: white;
    border-radius: 10px;
    flex-shrink: 0; /* Prevent shrinking */
    scroll-snap-align: start; /* Snap to start of each item */
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.pending-sr-unit-details{
    height: auto;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-direction: column;
    margin-bottom: 2.5px;
    text-align: right;
}

.pending-sr-unit-summary{
    height: 100%;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pending-sr-unit-summary-date-time{
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    /* background: red; */
}
.pending-sr-unit-summary-date{
    width: 100%;
    /* background: blue; */
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    font-size: 10px;
    font-weight: 700;
    color: #7d7d7d;
}
.pending-sr-unit-summary-time{
    width: 100%;
    /* background: blue; */
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    /* font-size: 11.1px; */
    font-weight: 700;
    color: var(--text-colour);
}


.pending-sr-unit-detail{
    height: 25px;
    width: 100%;
    background: var(--launch-colour2);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 700;
    color: #7d7d7d;
}

.pending-sr-unit-summary-btn-holder{
    height: 100%;
    display: flex;
    gap: 5px;
    padding-right: 5px;
}

.pending-sr-unit-summary-btn{
    height: 32.5px;
    width: 32.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100%;
    color: white;
    
}

#deny-shift{background: var(--launch-colour);color: #7d7d7d;}
#accept-shift{background: #007aff; color: white;}


.jobber-shift-resp-modal{
    height: auto;
    width: 100%;
    background: white;
    position: absolute;
    bottom: 0px;
    padding-top: 10px;
    padding-bottom: 20px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    flex-direction: column;
}

.jobber-modal-close-mobile{
    background: var(--launch-colour);
    width: 120px;
    height: 5px;
    border-radius: 3px;
    border: none;
    margin: auto;
    margin-bottom: 20px;
}

.jobber-shift-resp-modal-title{
    height: 30px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: var(--text-colour);
}

.jobber-shift-resp-modal-shift-block-holder{
    height: auto;
    width: calc(100% - 40px);
    gap: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.jobber-shift-resp-modal-shift-block{
    height: 80px;
    width: 100%;
    background: var(--launch-colour2);
    border-radius: 20px;
    display: flex;
    padding-bottom: 10px;
    flex-direction: column;

}

.jobber-shift-resp-modal-shift-block-top{
    height: 40px;
    width: auto;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-top: 10px;
}

.jobber-shift-resp-modal-shift-block-bottom{
    height: 40px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-left: 15px; */
    /* padding-top: 10px; */
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    padding-top: 10px;
    color: var(--text-colour);
}
