:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


.global-error-holder {
    height: calc(100vh - 130px);
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 13%;
}

.global-error-part {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    /* background: blue; */
    /* width: 42.5%; */
    /* padding-left: 10%; */
    
}

.global-error-part-title {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 65px;
    color: var(--dark-text);
    padding-bottom: 15px;
}

.global-error-part-subtitle {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 19px;
    color: #7d7d7d;
    padding-bottom: 10px;
    /* background: red; */
    margin-bottom: -6px;
}

.global-error-part-btn-holder {
    padding-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.global-error-btn:hover{
    background: var(--launch-colour);
}
.global-error-btn {
    height: 47.5px;
    padding-left: 25px;
    padding-right: 25px;
    border: none;
    border-radius: 10px;
    display: flex;
    gap:15px;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    cursor: pointer;
    color: var(--text-colour);
    /* background: var(--launch-highlight); */
    background: white;
    border:1px solid var(--launch-colour);
    transition:150ms ease-in-out;
    font-size: 14px;
}

#alt-global-error-btn {
    background: var(--worky-colour);
    color: white;
}
#alt-global-error-btn:hover {
    background: var(--hightlight-colour);
    
}

/* Mobile-Friendly Styles */
@media (max-width: 750px) {
    .global-error-holder {
        padding-left: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .global-error-part{
        display: flex;
        align-items: center;

    }

    .global-error-part-title {
        font-size: 45px;
    }

    .global-error-part-subtitle {
        font-size: 16px;
    }



    .global-error-btn {
        /* width: 80%; */
    }
}
