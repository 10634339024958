:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}



.clock-confirmation-modal-mobile{
    height: auto;
    width: 100vw;
    background: white;
    position: absolute;
    bottom: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 20px;
}

.clock-confirmation-modal-mobile-close-mobile{
    height: 6px;
    width: 35%;
    border: none;
    border-radius: 3px;
    background: var(--launch-colour);
    margin-top: 10px;
}

.clock-confirmation-modal-title-mobile{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    color: var(--text-colour);
    font-size: 20px;
    font-weight: 700;
}
.clock-confirmation-modal-subtitle-mobile{
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    padding-bottom: 15px;
    color: #7d7d7d;
    font-size: 17px;
    font-weight: 600;
}

.clock-confirmation-modal-button-holder{
    height: 80px;
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    gap: 10px;
}

.clock-confirmation-modal-button{
    height: 60px;
    width: 50% ;
    border: none;
    border-radius: 15px;
    font-family: 'DM Sans', sans-serif;
    color: var(--text-colour);
    font-size: 17.5px;
    font-weight: 700;
    transition: 200ms ease-in-out;
    cursor: pointer;
}



.clock-confirmation-modal{
    height: auto;
    width: 500px;
    background: white;
    position: absolute;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 15px;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}


.clock-confirmation-modal-mobile-close{
    height: 40px;
    width: 40px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: 15px;
    border: none;
    border-radius: 100%;
    background: none;
    color: var(--launch-highlight);
    transition: 250ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.clock-confirmation-modal-mobile-close:hover{
    background: var(--launch-colour2);
    color: var(--text-colour);
}
.clock-confirmation-modal-mobile-close:active{
    transform: scale(0.97);
}

.clock-confirmation-modal-title{
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    color: var(--text-colour);
    font-size: 23px;
    font-weight: 700;
    padding-bottom: 10px;
}

.clock-confirmation-modal-subtitle{
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    padding-bottom: 15px;
    color: #7d7d7d;
    font-size: 17px;
    font-weight: 600;
}

.clock-confirmation-modal-button:hover{
    background: var(--launch-highlight);
}

.clock-confirmation-modal-button:active{
    transform: scale(0.96);
}

.clock-table-container{
    height: auto;
    width: 100%;
    background: var(--launch-colour2);
    /* padding-top: 15px; */
    
}
.clock-table-holder{
    margin: auto;
    height: 100px;
    width: calc(100% - 40px);
    background: white;
    border-radius: 10px;
    border: 1px solid var(--launch-colour);
}
