:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}


.agency-shift-cluster-modal {
    max-height: calc(90vh - 40px);
    width: 92vw;
    background: white;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.agency-shift-cluster-modal-mobile {
    max-height: 90vh;
    width: calc(100vw - 32px);
    background: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    bottom:0px;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.agency-shift-cluster-close-mobile{
    margin: auto;
    height: 6px;
    min-height: 6px;
    border-radius: 3px;
    background: var(--launch-colour);
    width: 40%;
    margin-top: -5px;
    margin-bottom: 10px;
    border: none;
}

.agency-shift-cluster-close {
    color: #7d7d7d;
    background: none;
    border: none;
    cursor: pointer;
    transition: 150ms ease-in-out;
    padding: 8px;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; /* Added to position the button */
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    z-index: 10; /* Ensure it appears above other elements */
}

.agency-shift-cluster-close:hover {
    color: #5a5a5a; /* Darker shade on hover */
}

.agency-shift-cluster-close:active {
    transform: scale(0.92); /* Scale down slightly on click */
}



.agency-shift-cluster-modal-top-shelf{
    height: auto;
    width: 100%;
    /* background: red; */
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.agency-shift-cluster-top-shelf-title{
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    /* background: blue; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 23px;
    color: var(--text-colour);
}

.agency-shift-cluster-top-shelf-holder-mobile{
    height: 90px;
    width: 100%;
    background: var(--launch-colour2);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.agency-shift-cluster-top-shelf-holder{
    height: 170px;
    width: 100%;
    background: var(--launch-colour2);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;}

.agency-shift-cluster-top-shelf-arrow{
    height: 50px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: #7d7d7d;
    cursor: pointer;
    transition: 100ms ease-in-out;
}


.agency-shift-cluster-top-shelf-arrow:hover {
    color: #5a5a5a; /* Darker shade on hover */
}

.agency-shift-cluster-top-shelf-arrow:active {
    transform: scale(0.92); /* Scale down slightly on click */
}


.agency-shift-cluster-top-shelf-inner {
    height: 100%;
    width: max-content; /* Ensure it can grow based on content */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 20px;
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory; /* Smooth snapping for each cluster */

}
.agency-shift-cluster-top-shelf-inner-mobile {
    height: 100%;
    width: max-content; /* Ensure it can grow based on content */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 10px;
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory; /* Smooth snapping for each cluster */

}

.agency-shift-cluster:hover {
    border: 3px solid var(--launch-colour);
}
.agency-shift-cluster {
    height: 114px;
    width: 169px;
    background: white;
    flex-shrink: 0; /* Prevent shrinking */
    scroll-snap-align: start; /* Snap to start of each item */
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 7.5px;
    border: 3px solid white;
    cursor :pointer;
    transition: 150ms;
}
.agency-shift-cluster:active {
    transform: scale(0.98);
}
.agency-shift-cluster-mobile {
    height: 65px;
    width: 70px;
    background: white;
    flex-shrink: 0; /* Prevent shrinking */
    scroll-snap-align: start; /* Snap to start of each item */
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2.5px;
    border: 3px solid white;
    cursor :pointer;
    transition: 150ms;
}.agency-shift-cluster-mobile:active {
    transform: scale(0.98);
}

.agency-shift-cluster-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 23px;
    color: var(--text-colour);
}
.agency-shift-cluster-title-mobile{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: var(--text-colour);
}

.agency-shift-cluster-number{
    height: 30px;
    width: auto;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    background: var(--launch-colour2);
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #7d7d7d;
}

.agency-shift-cluster-number-mobile{
    height: 20px;
    width: auto;
    padding: 2.5px;
    padding-left: 5px;
    padding-right: 5px;
    background: var(--launch-colour2);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #7d7d7d;
}

#shift-cluster-active{
    border: 3px solid var(--launch-colour);
}




.agency-shift-cluster-top-shelf-arrow {
    background: none;
    border: none;
    cursor: pointer;
    color: #7d7d7d;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    transition: transform 150ms ease;
}


.agency-shift-cluster-modal-bottom-shelf{
    height: auto;
    width: 100%;
    /* background: green; */
    padding-top: 20px;
}


.agency-shift-cluster-bottom-shelf-inner {
    height: auto;
    width: calc(100% - 40px);
    max-height: 380px;
    background-color: var(--launch-colour2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px;
    position: relative;
}
.agency-shift-cluster-bottom-shelf-inner-mobile {
    height: auto;
    width: calc(100% - 40px);
    max-height: 600px;
    background-color: var(--launch-colour2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px;
    position: relative;
}

.agency-shift-cluster-inner-shift-mobile{
    height: 60px;
    width: calc(100%);
    /* padding-left:10px; */
    /* padding-right: 10px; */
    background: white;
    border-radius: 15px;
    flex-shrink: 0; /* Prevent shrinking */
    scroll-snap-align: start; /* Snap to start of each item */
    display: flex;
    align-items: center;
    justify-content: flex-start;

}
.agency-shift-cluster-inner-shift {
    height: 60px;
    width: calc(100% - 20px);
    /* padding-left:10px; */
    /* padding-right: 10px; */
    background: white;
    border-radius: 15px;
    flex-shrink: 0; /* Prevent shrinking */
    scroll-snap-align: start; /* Snap to start of each item */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}


.agency-shift-cluster-inner-shift-icon{
    height: 42.5px;
    width: 42.5px;
    background: var(--launch-colour);
    border-radius: 100%;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    color: #7d7d7d;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.agency-shift-cluster-inner-shift-icon img{
    height: 100%;
    width: 100%;
}

.agency-shift-cluster-inner-shift-name{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 17px;
    color: var(--text-colour);
}
.agency-shift-cluster-inner-shift-name-mobile{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 15.5px;
    color: var(--text-colour);
}

.agency-shift-cluster-inner-shift-workertype{
    background:var(--launch-colour2);
    padding: 6.5px;
    padding-left: 12.5px;
    padding-right: 12.5px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    transition: 150ms ease-in-out;
    cursor: default;
}

.agency-shift-cluster-inner-shift-time:hover{
    background: var(--launch-colour2);
    
}
.agency-shift-cluster-inner-shift-time{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: var(--text-colour);
    padding: 6.5px;
    padding-left: 12.5px;
    padding-right: 12.5px;    
    border-radius: 10px;    
    transition: 150ms ease-in-out;
} 
.agency-shift-cluster-inner-shift-time-mobile{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: var(--text-colour);
    border-radius: 10px;    
    transition: 150ms ease-in-out;
} 

#cluster-inner-warehouse {
    background-color: #e8f4f8; /* Soft blue background */
    color: #2a6a7f; /* Slightly darker blue for text */
}

#cluster-inner-warehouse:hover {
    background-color: #d2e8f0; /* Lighter blue background on hover */
}

/* Forklift Operator */
#cluster-inner-forklift {
    background-color: #f5f4e1; /* Soft yellowish background */
    color: #c0a800; /* Yellowish text color */
}

#cluster-inner-forklift:hover {
    background-color: #e2e1b0; /* Lighter yellow background on hover */
}

/* General Labour */
#cluster-inner-general {
    background-color: #f1f1f1; /* Light gray background */
    color: #575757; /* Dark gray text */
}

#cluster-inner-general:hover {
    background-color: #e1e1e1; /* Slightly darker gray background on hover */
}

.agency-shift-cluster-inner-shift-status{
    background:var(--launch-colour2);
    padding: 5px;
    padding-left: 12.5px;
    padding-right: 12.5px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    transition: 150ms ease-in-out;
    cursor: default;
    margin-left: auto;
    color: white;
}


.agency-shift-cluster-inner-expanded{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.5px;
    /* background: red; */
}


.agency-shift-cluster-inner-expanded-modification-holder{
    height: 50px;
    margin-left: auto;    
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.agency-shift-cluster-inner-expanded-modification-holder-mobile{
    height: 50px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 18px;

}


.agency-shift-cluster-inner-expanded-modification-btn-mobile{
    gap: 7.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: var(--launch-colour2);
    padding: 5px 10px;
    border-radius: 10px;
    color: var(--text-colour);
    transition: 150ms ease-in-out;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 10.5px;
}

.agency-shift-cluster-inner-expanded-modification-btn{
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: var(--launch-colour2);
    padding: 7.5px 15px;
    border-radius: 10px;
    color: var(--text-colour);
    transition: 150ms ease-in-out;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
}
.agency-shift-cluster-inner-expanded-modification-btn:hover{
    background: var(--launch-colour);
    transform: scale(1.025);
}
.agency-shift-cluster-inner-expanded-modification-btn:active{
    transform: scale(0.975);
}




.agency-shift-cluster-inner-expanded-detail-holder-mobile{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
}
.agency-shift-cluster-inner-expanded-detail-holder{
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: auto;
    /* background: lightgrey; */
    display: flex;
    align-items: center;
    gap: 20px;
    /* flex-direction: column; */
}

.agency-shift-cluster-inner-expanded-detail-col{
    /* background: red; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    gap: 10px;
}
.agency-shift-cluster-inner-expanded-detail-col-mobile{
    /* background: red; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}


.agency-shift-cluster-inner-expanded-detail-row{
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    /* background: red; */

}

.agency-shift-cluster-inner-expanded-detail-row-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--launch-highlight);
    font-size: 12px;
    height: auto;
    width: 100px;
    text-align: left;
}

.agency-shift-cluster-inner-expanded-detail-row-value{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: var(--text-colour);
    height: auto;
    
}
.agency-shift-cluster-inner-expanded-detail-row-value-mobile{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #7d7d7d;
    height: auto;
    
}


.simple-modify-modal{
    padding: 20px;
    height: auto;
    width: auto;
    min-width: 400px;
    background: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.simple-modify-modal-mobile{
    max-height: 95vh;
    width: calc(100vw - 32px);
    background: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    bottom:0px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.simple-modify-modal-close {
    color: #7d7d7d;
    background:none;
    border: none;
    cursor: pointer;
    transition: 150ms ease-in-out;
    /* padding: 8px; */
    /* border-radius: 5px; */
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -15px;
    margin-left: auto;
    margin-right: -10px;
    z-index: 10; /* Ensure it appears above other elements */
}
.simple-modify-modal-close-mobile {
    margin: auto;
    height: 6px;
    min-height: 6px;
    border-radius: 3px;
    background: var(--launch-colour);
    width: 40%;
    margin-top: -5px;
    margin-bottom: 10px;
    border: none;
}


.simple-modify-modal-close:hover {
    color: #5a5a5a; /* Darker shade on hover */
}

.simple-modify-modal-close:active {
    transform: scale(0.92); /* Scale down slightly on click */
}

.simple-modify-modal-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 19px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: calc(100% - 10px);
    color: var(--text-colour);
}

.simple-modify-modal-btn-holder{
    height: 50px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
/* .simple-bold{
    font-weight: 700;
} */
.simple-modify-modal-btn:hover{
    background: var(--launch-colour);

}
.simple-modify-modal-btn:active{
    transform: scale(0.98);
}
.simple-modify-modal-btn{
    height: 50px;
    width: 50%;
    border: none;
    border-radius: 15px;
    color: var(--text-colour);
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    background: var(--launch-colour2);
    cursor: pointer;
    transition: 150ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

#simple-modify-modal-btn-yes:hover{
    background: var(--hightlight-colour);
}
#simple-modify-modal-btn-yes{
    background: var(--worky-colour);
    color: white;
}


.simple-modify-modal-info{
    height: auto;
    padding: 20px;
    border-radius: 15px;
    width: calc(100% - 60px);
    background: var(--launch-colour2);
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.simple-modify-modal-info-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 16px; 
    width: 100%;
    text-align: left;
}

.simple-modify-modal-info-inner{
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}
.simple-modify-modal-info-inner-mobile{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.simple-modify-modal-info-inner-item{
    height: 20px;
    width: 100%;
    display: flex;
    /* background: blue; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.simple-modify-modal-info-inner-item-title{
    width: 110px;
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: var(--launch-highlight);
}
.simple-modify-modal-info-inner-item-value{
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: var(--text-colour);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.simple-modify-modal-info-inner-item-value-icon{
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background: #cdcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

@keyframes pending-gradient-opacity {
    0% {
        background-position: 0% 50%;
        opacity: 0.8; /* Slightly less opaque */
    }
    50%{
        background-position: 50% 50%;
        opacity: 1; /* Fully opaque */
    }
    100% {
        background-position: 100% 50%;
        opacity: 0.8; /* Slightly less opaque */
    }
}

#pending-shift-tag {
    background: linear-gradient(135deg, #f9f3c2, #f5d86e); 
    animation: pending-gradient-opacity 1.5s infinite;
    font-weight:700;
}

#assigned-shift-tag{background: linear-gradient(135deg, #d0f0d0, #8fd88f);font-weight:700;} 


.prev-jobber-holder-reassign-modal{
    padding-bottom: 20px;
    width: calc(100% - 20px);
}