:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}


.account-settings-page{
    width: 80%;
    margin: auto;
    /* display: flex; */
    /* flex-direction: column; */
}

.account-settings-title2{
    height: 35px;
    /* width: 100%; */
    /* padding-left: 10%; */
    font-size: 35px;
    text-align: left;
    text-indent: 50px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    /* padding-bottom: 10px; */
    /* background: red; */
    margin-top: 70px;
    position: absolute;
}

.profile-settings-container{
    height: auto;
    padding-top: 40px;
    padding-bottom: 50px;
    /* width: calc(70% - 10px); */
    /* width: 0%; */
    margin: auto;
    margin-top: 120px;
    /* background: red; */
    background: white;
    /* margin-top: 10px; */
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 30px; */
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
}



.profile-icon {
    width: 120px;
    height: 120px;
    background: var(--launch-colour2);
    border-radius: 50%; /* Set border-radius to 50% for a circular shape */
    overflow: hidden; /* Ensure the image doesn't overflow the circular container */
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-icon img {
    width: 105%; 
    height: auto;
    border-radius: 50%;
}

.profile-pic img {
    width: 105%; 
    height: auto;
    border-radius: 50%;
}


.profile-pic {
    width: 120px;
    height: 120px;
    background: var(--launch-colour2);
    border-radius: 50%; /* Set border-radius to 50% for a circular shape */
    overflow: hidden; /* Ensure the image doesn't overflow the circular container */
    display: flex;
    align-items: center;
    justify-content: center;
}


.profile-info-holder{
    display:flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    /* background: red; */
    width: 400px;
    /* padding-left: 20px; */
}


.profile-namev2{
    text-align: center;
    display: flex;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
    color: var(--text-colour);
    padding: 15px;
    /* text-align: left; */
}


.chip-holder{
    height: auto;
    width: 600px;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: red; */
}

.profile-title{
    display: flex;
    /* text-align: center; */
    width: 100%;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: var(--text-colour);
    /* text-indent: 5px; */
    /* background-color: red; */
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.profile-company{
    width: 100%;
    /* text-align: left; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: var(--text-colour);
    /* text-indent: 5px; */
    font-size: 15px;
    display: flex;
    /* text-align: left; */
}




.profile-account-jobbers{
    /* height: 100px; */
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%; 
    /* width: calc(40% - 60px); */
    background: white;
    margin: auto;
    margin-top: 90px;
    border-radius: 30px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
}

#second{
    margin-top: 40px;
}

.profile-account-jobbers-inner{
    height: auto;
    width: calc(100% - 60px);
    /* width: 80%; */
    /* background: red; */
    margin: auto;
    display: flex;
    flex-direction: column;
    /* flex-wrap: nowrap; */
    gap:8px
}


















.account-buttons{
    margin: auto;
    margin-top: 10px;
    /* margin-bottom: 50px; */
    display: flex;
    flex-direction: column;
    height: 80px;
    width: 100%;
    align-items: center;
    justify-content:space-between;
    /* background-color: red; */
}


#modal2{
    height: 300px;
}
#message2{
    padding-top: 20px;

}

.account-settings-change{
    border: none;
    background: none;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    color: #7d7d7d;
}

.account-settings-change:hover{
    color: var(--worky-colour);
}

.account-settings-signout{
    height: 50px;
    width: 155px;
    background: var(--worky-colour);
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
}

.account-settings-signout:hover{
    background: var(--hightlight-colour);
}

.account-settings-container-mobile{
    height: auto;
    width: 90%;
    margin: auto;
    /* padding-top: 20px; */
    padding-bottom: 20px;
    /* background-color: red; */
    margin-top: 20px;

}
.general-settings-container-mobile{
    height: auto;
    width: 90%;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 20px;
    /* background-color: red; */

}
.general-settings-container-mobile2{
    height: auto;
    width: 90%;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 20px;
    /* background-color: red; */

}


.profile-info-holder-mobile{
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-top: 20px; */
    padding-bottom: 10px;

}
.general-info-holder-mobile{
    height: auto;
    width: 100%;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    /* gap: 7.5px; */
}

.general-info-holder-mobile2{
    height: auto;
    width: calc(100% - 15px);
    gap: 7.5px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 7.5px;
    padding-bottom: 7.5px;
    /* gap: 7.5px; */
}


.profile-settings-title-mobile{
    /* background: red; */
    display: flex;
    padding-left: 7.5%;
    color: var(--launch-highlight);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 13px;
}

.general-info-row-mobile{
    height: 40px;
    /* background: red; */
    
    width: 95%;
    display: flex;
    align-items: center;
    border-radius: 7.5px;
}

/* .general-info-row-mobile:hover{
    background: var(--launch-colour2);
} */

.general-info-row-icon{
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--launch-highlight);
    /* background: blue; */
}

.general-info-line-mobile{
    height: 1px;
    width: 87%;
    background: var(--launch-colour);


}

#org-account-mobile{
    height: auto;
    width: 95%;
    margin: auto;
    border-radius: 10px;
    /* background: var(--launch-colour2); */
    margin-top: 11.25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.general-info-row-value{
    height: 100%;
    width: 85%;
    display: flex;
    align-items: center;
    color: var(--text-colour);
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    overflow-x: scroll;
    color: #7d7d7d;
    /* background: green; */
}