:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}




.agency-card-holder {
    height: 100px;
    width: calc(100% - 50px);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    overflow-x: auto;
    overflow-y: hidden;
  }
.agency-card-holder-mobile{
    height: 70px;
    width: calc(100% - 30px);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  .agency-card {
    height: 100%;
    width: 220px;
    padding-left: 15px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 17px;
    border: 1px solid var(--launch-colour);
  }
  .agency-card-mobile {
    height: 100%;
    width: 220px;
    padding-left: 15px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 17px;
    border: 1px solid var(--launch-colour);
  }

  
  .agency-card-top {
    height: 50px;
    /* background: red; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    color: var(--text-colour);
  }
  .agency-card-top-mobile{
    height: 50px;
    /* background: red; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    color: var(--text-colour);
  }
  
  .agency-card-icon{
    height: 35px;
    /* width: 35px; */
    /* background: var(--launch-colour); */
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .agency-card-icon-mobile{
    margin-left: -5px;
    height: 35px;
    /* width: 35px; */
    /* background: var(--launch-colour); */
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .agency-card-title {
    font-size: 16px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    /* margin-top: 5px; */
    color: var(--text-colour);
  }
  .agency-card-title-mobile{
    font-size: 10px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    /* margin-top: 5px; */
    color: var(--text-colour);
    
  }
  
  .agency-card-bottom {
    /* height: 125px; */
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  .agency-card-bottom-mobile{
    /* height: 125px; */
    /* height: calc(100% - 70px); */
    width: calc(100% + 15px);
    margin-left: -15px;
    display: flex;
    /* background: red; */
  }
  
  .agency-card-value {
    font-size: 28px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .agency-card-value-mobile{
    font-size: 18px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 33px;
    /* background: red; */
  }




  .agency-card-value2 {
    font-size: 25px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 10px;
  }
  .agency-card-value-mobile2{
    font-size: 15px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 33px;

  }









.agency-paystub-title{
    font-size: 17px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #4c4c4c;
    height: auto;
    width: calc(100% - 50px);
    background: var(--launch-colour2);
    display: flex;
    padding:25px;
    padding-bottom: 0px;
}



.agency-paystub-holder{
    height: auto;
    width: calc(100% - 50px);
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    overflow: hidden;
    background: var(--launch-colour2);
}
.agency-paystub-holder-mobile{
    height: auto;
    width: calc(100% - 30px);
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    overflow: hidden;
    background: var(--launch-colour2);
}


.all-pay-stub-table-holder{
    /* background: white; */
    border-radius: 10px;
    /* border: 1px solid var(--launch-colour2); */
    /* border: 1px solid red; */
    /* overflow: hidden; */

}



.current-paystub-container{
    min-height: 110px;
    height: auto;
    /* background: red; */
    /* width: calc(100% - 50px); */
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.paystub-outer-row{
    height: 30px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background: blue; */
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

#ps-col1{width: 250px;}
#ps-col2{width: 100px;}
#ps-col3{width: 150px;}
#ps-col4{width: 200px;}
#ps-col5{
    margin-left: auto; 
    margin-right: 20px;
    height: 35px;
    width: 35px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    /* background: var(--launch-colour2); */
    color: var(--text-colour);
    cursor: pointer;
}
#ps-col5:hover{ 
    background: var(--launch-colour2);
}

.paystub-outer-col-inner{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    padding-left: 20px;
    color: var(--launch-highlight);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.current-paystub-holder{
    height: 70px;
    width: 100%;
    background: white;
    border-radius: 10px;
    border: 1px solid var(--launch-colour);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    transition: 200ms ease-in-out;
}

#current-paystub-holder-expanded{
    min-height: 350px;
    height: auto;
    padding-bottom: 10px;
    /* max-height: 450px; */
    transition: 200ms ease-in-out;
}

#current-paystub-holder-expanded-mui{
    min-height: 290px;
    max-height: 450px;
    max-width: calc(100vw - 85px);
    /* margin-left: -15px; */
    padding-top: 20px;
    padding-bottom: 20px;
    transition: 200ms ease-in-out;

}
#current-paystub-holder-expanded-mui-mobile{
    min-height: 290px;
    max-width: calc(100vw - 88px);
    /* margin-left: -15px; */
    /* background: red; */
    /* padding-top: 20px; */
    transition: 200ms ease-in-out;

}


.current-paystub-col-inner-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;

}

.current-paystub-col-inner{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding-left: 20px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;    
    color: var(--text-colour);
    height: 70px;
}


.expanded-current-paystub-col-inner-holder{
    height: calc(100% - 90px);
    width: calc(100% - 40px);
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.expanded-current-paystub-col-inner-holder-mobile{
    height: calc(100% + 40px);
    width: calc(100% + 45px);
    margin-left: -20px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    padding-bottom: 10px;
    flex-direction: column;
}
.expanded-current-paystub-col-inner-holder-mobile1{
    height: calc(100% + 40px);
    width: calc(100% - 20px);
    /* margin-left: -20px; */
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    padding-bottom: 10px;
    flex-direction: column;
}


.expanded-current-paystub-left{
    height: 100%;
    width: 27%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.expanded-current-paystub-left-mobile{
    height: 100%;
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}


.expanded-current-paystub-left-corp-obligations{
    height: calc(100% - 0px); 
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    width: calc(100%); 
    /* background: red; */
    /* border-radius: 20px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    flex-direction: column;    

}
.expanded-current-paystub-left-corp-obligations-mobile{
    height: calc(100% - 0px); 
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    width: calc(100%); 
    /* background: red; */
    /* border-radius: 20px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    flex-direction: column;   
}

.corp-obligation{
    height: 50px;
    width: calc(100%); 
    background: var(--launch-colour2);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.corp-obligation-mobile{
    height: 50px;
    width: calc(100%); 
    background: var(--launch-colour2);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.corp-obligation-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;    
    color: var(--text-colour);
    margin-left: 15px;
}
.corp-obligation-value{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;    
    color: var(--text-colour);
    margin-right: 15px;
}


.expanded-current-paystub-right{
    height: 100%;
    width: calc(73% - 60px);
    margin-left: 60px;
    /* background: blue; */
}
.expanded-current-paystub-right-mobile{
    height: 100%;
    width: calc(100% - 25px);
    margin-left: 2px;
    /* background: blue; */
}

.expanded-current-paystub-right-shift-details{
    height: 100%;
    width: 100%;
    /* background: var(--launch-colour2); */
    /* border-radius: 20px; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.paystub-right-shift-details-title{
    height: 45px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 17px;    
    color: var(--text-colour);
}
.paystub-right-shift-details-table-holder{
    max-height: calc(100% - 45px);
    width: 100%;

}

.grid-row{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.col-img{
    height: 35px;
    width: 35px;
    border-radius: 100%;
}

#mui-table-header{
    font-size: 15px;
    font-weight: 600;
    color: var(--launch-highlight);
    border: none;
}


.mui-table-body{
    background: white;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid black;
}


#mui-table-cell{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;    
    color: var(--text-colour);
}