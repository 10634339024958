:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}


.timeclock-table-holder{
    background: var(--launch-colour2);
    width: calc(100% - 50px);
    margin: auto;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
}
.timeclock-table-holder-mobile{
    background: var(--launch-colour2);
    width: calc(100% - 40px);
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}

.grid-icon-holder{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 500;
    font-size: 14px;
}
.grid-inner-holder{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: -1px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 500;
    font-size: 14px;
}

.checkin-tag{
    height: 30px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 16.25px;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 700;
    font-size: 13px;
}

.ontime-tag{
    height: 30px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 16.25px;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: white;
    font-weight: 700;
    font-size: 13px;
}
#notclocked {background: linear-gradient(135deg, #d7e7f7, #a3c9e8);}
#clockedontime {background: linear-gradient(135deg, #d0f0d0, #8fd88f);} 
#clockedearly {background: linear-gradient(135deg, #f9f3c2, #f5d86e); }
#clockedlate {background: linear-gradient(135deg, #f8d0d0, #f39393); }

.timeclock-modal-inner-holder{
    height: auto;
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 11px;
    width: calc(100% - 40px);
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.timeclock-modal-inner-holder-two{
    height: auto;
    padding-top: 10px;
    margin-top: 15px;
    padding-bottom: 10px;
    border-radius: 11px;
    width: calc(100% - 40px);
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.timeclock-modal-inner-btn{
    height: 60px;
    width: 100%;
    border: none;
    background: var(--launch-colour2);
    border-radius: 15px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    transition: 200ms ease-in-out;
}
.timeclock-modal-inner-btn:hover{
    background: var(--launch-colour);
}
.timeclock-modal-inner-btn:active{
    transform: scale(0.98);
}

.timeclock-close-mobile{
    margin-top: 10px;
    margin-bottom: 15px;
    width: 32.5%;
    color: var(--launch-colour2);
    border: none;
    height: 6px;
    border-radius: 3px;
}





.agency-home-clock-container{
    height: auto;
    padding-bottom: 15px;
    width: 330px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;
}
.agency-home-clock-container-mobile{
    height: auto;
    width: calc(100% - 30px);
    padding-left: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.agency-home-clock-holder{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
}

.agency-home-clock-holder-mobile {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Prevent vertical scrolling */
    padding-left: 10px;
    /* padding: 10px; Add some padding for better visuals */
    box-sizing: border-box; /* Ensure padding doesn't affect the width */
    scroll-behavior: smooth; /* Optional: smooth scrolling effect */
}

.clock-card {
    height:75px;
    width: 300px;
    border-radius: 12.5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0; /* Prevent shrinking */
}

.clock-card-status-bar{
    height: 100%;
    width: 7.5px;
    /* background: red; */
}




.clock-card-inner{
    width: calc(100% - 7.5px);
    height: 100%;
    background: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}


.clock-card-inner-top{
    height: 40px;
    padding-top: 5px;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clock-card-inner-top-left {
    height: 40px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7.5px;
}

.clock-card-inner-top-icon-holder {
    height: 34px;
    width: 34px;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--launch-highlight);
    border-radius: 100%;
}

.clock-card-inner-top-title-holder {
    height: 40px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.clock-card-inner-top-title-checktype {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 700;
    font-size: 15px;
    width: 100%;
    text-align: left;
}

.clock-card-inner-top-title-checkdate {
    width: 100%;
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-size: 10px;
    font-weight: 600;
    margin-top: -2.5px;
}


.clock-card-inner-middle {
    height: 35px;
    padding-top: 7.5px;
    padding-bottom: 5px;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clock-card-inner-middle-time-holder {
    height: 37.5px;
    width: 50%;

}

.clock-card-inner-middle-time-title {
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-size: 10px;
    font-weight: 600;
}

.clock-card-inner-middle-time-value {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 700;
    font-size: 18px;
    text-align: left;
}

.clock-card-inner-bottom {
    height: 25px;
    width: calc(100% - 20px);
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clock-card-inner-bottom-left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.clock-card-inner-bottom-left-icon {
    height: 25px;
    width: 15px;
    /* margin-top: -2.5px; */
    /* background: var(--launch-colour2); */
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--launch-highlight);
}

.clock-card-inner-bottom-left-address {
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-size: 10px;
    font-weight: 600;
}


.clock-card-expand-inner{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 35px;
    border-radius: 10px;
    background: white;
    border: none;
    color: #7d7d7d;
    transition: 150ms ease-in-out;
    cursor: pointer;
    padding-left: 15px;
}
.clock-card-expand-inner:hover{padding-left: 25px;}
.clock-card-expand-inner:active{color: var(--worky-colour);}



.empty-state{
    width: 92.5%;
    margin: auto;
    height: 100px;
    /* background: white; */
    /* border: 1px solid var(--launch-colour); */
    border-radius: 15px;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 500;
    font-size: 19px;
}

@keyframes sync-icon-rotation {
    0% {
      transform: rotate(0deg) scaleX(-1);
    }
    50% {
      transform: rotate(180deg) scaleX(-1);
      animation-timing-function: ease-out;
    }
    51% {
      animation-timing-function: ease-in;
    }
    100% {
      transform: rotate(360deg) scaleX(-1);
    }
  }
  
  .sync-icon {
    animation: sync-icon-rotation 6s linear infinite;
  }