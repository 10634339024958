
.resource-input{
    margin-top: 30px;
    width: 300px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    border: 1px solid #7d7d7d;
    font-family: 'DM Sans', sans-serif;
}


.resource-holder{
    padding-top: 140px;
    width: calc(100% - 180px);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 150px;
    flex-direction: column;
}


.resource-block{
    height: 400px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.resource-block-mobile{
    height: auto;
    width: calc(100vw - 70px);
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
}

.resource-block-textbox{
    /* background: red; */
    width: calc(100% - 550px);
    height: 400px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.resource-block-textbox-title{
    
    font-size: 40px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--text-colour);
    text-align: left;
    
}

.resource-block-textbox-text{
        
    /* font-size: 40px; */
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    color: var(--text-colour);
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;
}

.resource-block-img-holder{
    height: 400px;
    width: 500px;
    background: var(--launch-colour2);
    border-radius: 40px;
    overflow: hidden;
}
.resource-block-img-holder-mobile{
    height: 400px;
    width: 100%;
    background: var(--launch-colour2);
    border-radius: 40px;
    overflow: hidden;
}

.resource-block-img-holder img,
.resource-block-img-holder-mobile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}