:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}

.header-selection-toggle{
    height: 50px;
    width: auto;
    max-width: 100px;
    background: var(--launch-colour);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
}

.header-selection-toggle-btn{
    height: 40px;
    width: 47.5px;
    border-radius: 8px;
    border: none;
    background: white;
    background: none;
    transition: 0.15s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7d7d7d;
    cursor: pointer;
}
.header-selection-toggle-btn:hover{
    /* background: var(--launch-colour2); */
    color: var(--text-colour);
}
#header-selection-toggle-btn-active{
    background: var(--worky-colour);
    color: white;
}
#header-selection-toggle-btn-active:hover{
    background: var(--hightlight-colour);
}




.workforce-container-grid{
    height: calc(100vh - 195px);
    width: calc(100vw - 40px);
    margin-left: 20px;
    margin-right: 20px;
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 19px;
    flex-wrap: wrap;
    overflow-y: auto;
}
.workforce-container-grid-mobile{
    height: auto;
    width: calc(100vw - 40px);
    margin-left: 20px;
    margin-right: 20px;
    /* background: red; */
    margin-top: 15px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 19px;
    flex-wrap: wrap;
    overflow-y: auto;
}

.workforce-container-list{
    max-height: calc(100vh - 195px);
    width: calc(100vw - 40px);
    margin-left: 20px;
    margin-right: 20px;
    background: white;
    display: flex;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    gap: 19px;
    flex-wrap: wrap;
    overflow-y: auto;
}
.workforce-container-list-mobile{
    height: auto;
    width: calc(100vw - 40px);
    margin-left: 20px;
    margin-right: 20px;
    background: white;
    margin-top: 15px;
    display: flex;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    gap: 19px;
    flex-wrap: wrap;
    overflow-y: auto;
}


.jobber-workforce-card{
    height: 275px;
    width: 350px;
    background: white;
    border-radius: 20px;
    transition: 0.15s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* cursor: pointer;     */
}
.jobber-workforce-card-inner{
    height: auto;
    width: 350px;

    /* cursor: pointer;     */
}

.jobber-workforce-card:hover{
    box-shadow: 0 0px 8px 0 rgba(87, 87, 87, 0.1);
}

.jobber-workforce-card-mobile{
    height: 150px;
    width: 100%;
    background: white;
    border-radius: 20px;
    transition: 0.15s ease-in-out;
    display:flex;
    align-items: center;
    justify-content: center;
    /* cursor: pointer;     */
}


.jobber-workforce-card-icon-holder{
    /* height: 150px; */
    width: calc(100% - 10px);
    /* margin: auto; */
    /* background: var(--launch-colour2); */
    border-radius: 18px;
    padding-top: 10px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.jobber-workforce-card-icon-holder-mobile{
    width: 145px;
    /* background: red; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* margin-left: 150px; */
}

.jobber-workforce-card-icon{
    height: 95px;
    width: 95px;
    background: var(--launch-colour);
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0px 8px 0 rgba(87, 87, 87, 0.1);
}
.jobber-workforce-card-icon-mobile{
    height: 95px;
    width: 95px;
    background: var(--launch-colour);
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0px 8px 0 rgba(87, 87, 87, 0.1);
}

.jobber-workforce-card-icon-mobile img {
    height: 100px;
    
}
.jobber-workforce-card-icon img {
    height: 100px;
    
}

.jobber-workforce-card-rating-mobile{
    height: 20px;
    width: auto;
    background: var(--launch-colour2);
    border-radius: 10px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
}
.jobber-workforce-card-rating{
    height: 20px;
    width: auto;
    background: var(--launch-colour2);
    border-radius: 10px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
}

.jobber-workforce-card-name-mobile{
    font-size: 18px;
    height: 25px;
    width: 100%;

    text-align: left;
    padding-top: 12.5px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 600;
}
.jobber-workforce-card-name{
    font-size: 18px;
    height: 25px;
    /* background: red; */
    padding-top: 7.5px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 600;
}

.jobber-workforce-card-city-mobile{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-weight: 500;
    font-size: 14px;
    
    text-align: left;
}
.jobber-workforce-card-city{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-weight: 500;
    font-size: 14px;
    /* background: red; */
    height: 20px;
    margin: auto;
    padding-left: 9px;
    overflow-x: auto;
}

.jobber-workforce-card-availability-mobile {
    height: auto; /* Allow the height to adjust dynamically */
    width: auto;
    display: flex;
    flex-wrap: wrap; /* Enable wrapping of inner elements */
    gap: 5px; /* Space between the wrapped elements */
    /* padding-top: 2.5px; */
    margin-left: -2.5px;
    align-items: center; /* Optional: Align items vertically */
    justify-content: flex-start; /* Optional: Align items horizontally */
}
.jobber-workforce-card-availability{
    height: 35px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* background: red; */
    margin-top: 2.5px;
}

.card-availability-icon-mobile{
    height: 25px;
    width: auto;
    background: var(--launch-colour2);
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-size: 12.5px;
    font-weight: 600;
    border-radius: 12.5px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.card-availability-icon{
    height: 25px;
    width: auto;
    background: var(--launch-colour2);
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-size: 12.5px;
    font-weight: 600;
    border-radius: 12.5px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.jobber-workforce-card-details-holder-mobile{
    height: auto;
    width: calc(100% - 145px);
    /* background: blue; */
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column;
}
.jobber-workforce-card-details-holder{
    height: 55px;
    width: calc(100% - 15px);
    margin: auto;
    border-radius:17.5px ;
    /* background: var(--launch-colour2); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
}

.jobber-workforce-card-detail-top-holder-mobile{
    height: 75px;
    /* background:blue ; */

}


.jobber-workforce-card-detail-holder-mobile{
    height: calc(100% - 75px);
    /* background: red; */
    width: calc(100% - 5px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 10px;
}

.jobber-workforce-card-detail-mobile {
    height: calc(100% / 3);
    /* background: green; */
    /* width: 90%; */
    display: flex;
    align-items: center;
    gap: 5px;
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden;
    white-space: nowrap; /* Prevent wrapping of content */
}

.jobber-workforce-card-detail-title-mobile{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}


.jobber-workforce-card-detail {
    height: 48%;
    width: 90%;
    display: flex;
    align-items: center;
    gap: 5px;
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden;
    white-space: nowrap; /* Prevent wrapping of content */
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.jobber-workforce-card-detail-mobile::-webkit-scrollbar {
    display: none;
}
.jobber-workforce-card-detail::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for other browsers */
.jobber-workforce-card-detail-mobile {
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    scrollbar-width: none; /* For Firefox */
}
.jobber-workforce-card-detail {
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    scrollbar-width: none; /* For Firefox */
}


.jobber-workforce-card-detail-title{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jobber-workforce-card-line{
    height: 1px;
    width: 85%;
    background: var(--launch-colour);
}

.jobber-workforce-card-detail-value-mobile:hover{
    background: var(--launch-colour2);
}
.jobber-workforce-card-detail-value-mobile{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 7.5px;
    padding: 2px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    transition: 100ms ease-in-out;
}
.jobber-workforce-card-detail-value:hover{
    background: var(--launch-colour2);
}
.jobber-workforce-card-detail-value{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 7.5px;
    padding: 2px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    transition: 100ms ease-in-out;
}

.list-cell-icon-holder{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-cell-icon{
    height: 37px;
    width: 37px;
    background: var(--launch-colour2);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-cell-availability-holder{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}


.css-pdct74-MuiTablePagination-selectLabel{
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: var(--text-colour);
    font-weight: 700;

}

.css-levciy-MuiTablePagination-displayedRows{
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: var(--text-colour);
    font-weight: 700;

}
.css-194a1fa-MuiSelect-select-MuiInputBase-input{
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: var(--text-colour);
    font-weight: 700;
}
.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem{
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: var(--text-colour);
    font-weight: 700;

}

.workforce-search-container-mobile{
    /* background: red; */
    width: calc(100% - 20px);
    margin: auto;
    height: 45px;
    margin-bottom: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: center;

}
.workforce-search-container{
    /* background: red; */
    width: 350px;
    /* margin: auto; */
    /* margin-bottom: 25px; */
    height: 49px;
    top: 77px;
    right: 130px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
}
.workforce-search-container-alt{
    /* background: red; */
    width: 350px;
    /* margin: auto; */
    /* margin-bottom: 25px; */
    height: 49px;
    top: 77px;
    right: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
}

.workforce-search-input-alt{
    height: 45px;
    width: 100%;
    text-indent: 15px;
    border: 1px solid var(--launch-highlight);
    border-radius: 10px;

    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: var(--text-colour);
    font-weight: 600;
}
.workforce-search-input{
    height: 45px;
    width: 90%;
    text-indent: 15px;
    border: 1px solid var(--launch-highlight);
    border-radius: 10px;

    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: var(--launch-highlight);
    font-weight: 600;
}

.workforce-search-input::placeholder{
    color: #b0b0b0;
}

.invoice-tag-holder{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    gap: 10px;
}

.tooltip-worky{
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: white;
}