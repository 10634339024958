:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@import url('https://fonts.googleapis.com/css2?family=DM Sans:wght@400;700&display=swap');


.agency-billing-modal{
    background-color: white;
    height:100vh;
    width: 75vw;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.agency-billing-modal-mobile{
    background-color: white;
    height: auto;
    /* height: 77vh; */
    max-height: 90vh;
    padding-bottom: 40px;
    width: 100vw;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    /* transition: 350ms ease-in-out; */
}

.agency-billing-close-mobile{
    border: none;
    /* background: var(--launch-colour); */
    height: 6px;
    border-radius: 3px;
    background: none;
    width: 120px;
    margin-top: 10px;
}

.agency-billing-close{
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-colour);
    /* background: var(--launch-colour2); */
    background: none;
    border: none;
    border-radius: 100%;
    position: absolute;
    left: 25px;
    top: 25px;
    cursor: pointer;
    transition: 250ms ease-in-out;
}
.agency-billing-close:hover{background: var(--launch-colour);}
.agency-billing-close:active{transform: scale(0.95);}

.agency-billing-modal-icon{
    height: 60px;
    width: 60px;
    margin-top: 35px;
    margin-bottom: 10px;
    background: var(--launch-colour);
    border-radius: 100%;
}

.agency-billing-modal-orgname{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 19px;
    color:var(--text-colour);
}

.agency-billing-modal-title{
    /* height: 60px; */
    width: calc(100% - 40px);
    /* background: red; */
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 19px;
    color:var(--text-colour);
}

.agency-billing-modal-inner-holder{
    height: auto;
    padding-top: 12.5px;
    margin-top: 12.5px;
    padding-bottom: 15px;
    border-radius: 11px;
    width: calc(100% - 40px);
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.agency-billing-modal-inner-holder-two{
    height: auto;
    padding-top: 12.5px;
    /* margin-top: 12.5px; */
    padding-bottom: 15px;
    border-radius: 11px;
    width: calc(100% - 60px);
    background: white;
    /* background: var(--launch-colour2); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.agency-billing-modal-shift-holder{
    max-height: 330px;

    border-radius: 11px;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    background: white;
    /* background: var(--launch-colour2); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.agency-billing-modal-inner-value-holder{
    /* background: red; */
    height: 23px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    /* flex-direction: column; */
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
}


.agency-billing-modal-payments-title{
    margin-top: 20px;
    margin-bottom: 2.5px;
    width: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #aaaaaa;
}
.agency-billing-modal-payments{
    height: 45px;
    width: calc(100% - 50px);
    background: var(--launch-colour2);

    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
    padding-right: 5px;
}
.agency-billing-modal-payments-inner{
    background: var(--launch-colour);
    background:var(--worky-colour);
    background:#73a3f7;
    height: 35px;
    border-radius: 8px;
    min-width: 5%;
    /* width: 100%; */
    max-width: 100%;
    color: var(--launch-colour2);

}

.agency-billing-modal-payments-inner-txt{
    position: absolute;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--text-colour);
    width: calc(100% - 50px);
}


.agency-billing-modal-inner-title{
    color: #aaaaaa;
}

.agency-billing-modal-inner-value{
    font-weight: 700;
    color:var(--text-colour);
}



.agency-billing-modal-inner-price{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: var(--dark-text);
}

.agency-billing-modal-inner-daterange{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: var(--text-colour);
    margin-top: 5px;
}





.agency-billing-modal-info-holder{
    height: auto;
    width: calc(100% - 50px);
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    background: red;
    margin-top: 100px;
    
}

.agency-billing-table-holder{
    height: auto;
    width: calc(100% - 50px);
    padding-left: 25px;
    padding-right: 25px;
    background: var(--launch-colour2);
}


.agency-billing-inner-table-header{
    font-family: 'DM Sans', sans-serif;
    font-weight: 800;
    font-size: 12px;
    color: var(--text-colour);
}