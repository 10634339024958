:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}



.workypay-top-card{
    height: 175px;
    /* min-width: 500px; */
    padding-left: 20px;
    padding-right: 20px;
    max-width: calc(100% - 40px);
    min-width: 175px;
    overflow-x: auto;
    overflow-y: hidden;
    background: var(--launch-colour2);
}
.workypay-top-card-mobile{
    height: 270px;
    min-width: 230px;
    border-radius: 25px;
    background: var(--launch-colour2);
}

.holiday-holder{
    height: 60px;
    padding-top: 5px;
    /* background: red; */
    width: calc(100% + 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.holiday-row{
    height: 20px;
    width: 100%;
    /* background: blue; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 5px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color: #7d7d7d;
    font-size: 13px;
}

.holiday-title{
    width: 90px;
    max-width: 100px;
    overflow: hidden;
    /* background-color: blue; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
}

.holiday-value{
    font-weight: 800;
}

.section-title-container{
    padding-top: 20px;
    padding-bottom: 20px;
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    background: var(--launch-colour2);
}


.upcoming-payrun-container{
    height: auto;
    max-height: 210px;
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--launch-colour2);
    overflow: visible;
}



.upcoming-payrun-holder:hover{box-shadow: 0 4px 32px 0 rgba(222, 222, 222, 0.9);}
.upcoming-payrun-holder{
    height: 100%;
    background: white;
    width: 85%;
    border-radius: 20px;
    border: 1px solid var(--launch-colour);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 10px;
}

.upcoming-payroll-row-holder{
    max-height: 140px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}


.upcoming-payroll-row:hover{background: var(--launch-colour2);}
.upcoming-payroll-row{
    min-height: 45px;
    width: calc(100% - 20px);
    transition: 0.15s ease-in-out;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.upcoming-payroll-row-header{
    height: 50px;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upcoming-payroll-col{
    height: 100%;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    color:var(--text-colour);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upcoming-all-icon-holder:hover{
    background: var(--launch-colour);
}
.upcoming-all-icon-holder{
    height: 35px;
    width: 35px;
    border: none;
    border-radius: 10px;
    background: var(--launch-colour2);
    cursor: pointer;
    transition: ease-in-out 0.10s;
}

#upcoming-type{
    width: calc(25% - 10px);
    justify-content: flex-start;
    padding-left: 10px;
    color: #7d7d7d;
    font-weight: 500;
    font-size: 15px;
}
#upcoming-dates{
    width: 44%;
    color: #7d7d7d;
    font-weight: 500;
    font-size: 15px;
}
#upcoming-status{
    width: 25%;
}

#upcoming-all{
    width: calc(5% - 5px);
    justify-content: flex-end;
    padding-right: 5px;
}



#upcoming-type-title{
    width: calc(25% - 10px);
    justify-content: flex-start;
    padding-left: 10px;
    font-weight: 700;
}
#upcoming-dates-title{
    width: 44%;
    font-weight: 700;
}
#upcoming-status-title{
    width: 25%;
    font-weight: 700;
}

#upcoming-all-title{
    width: calc(5% - 5px);
    justify-content: flex-end;
    padding-right: 5px;
    font-weight: 700;
}








