:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}


.account-settings-container{
    background:white;
    border-radius:30px ;
    margin-top: 20px;
    width: 100%;
    height:300px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 120px;
}


.profile-content-top{
    height: 140px;
    width: calc(100% - 40px);
    margin: auto;
    background: white;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--launch-colour);
    gap: 30px;
}
.profile-name{
    height: 95%;
    width: 100%;
    text-align: left;
    display: flex;
    align-items:flex-end;
    font-size: 25px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
}

.profile-pic{
    height: 140px;
    width: 140px;
    background: var(--launch-colour);
    border-radius: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.account-settings-icon{
    height: 130px;
    border-radius: 130px;
}


.profile-info{
    height: 140px;
    width: calc(100% - 175px);
    /* background: var(--launch-colour); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.profile-info-half{
    height: 70px;
    width: 100%;
    /* background: grey; */
    display: flex;
    gap: 10px;
}

.profile-card-top{
    height: 70px;
    width: calc(20% - 15px);
    /* background: red; */
    display: flex;
    flex-direction: column;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.profile-card-top-title{
    padding-top: 10px;
    height: 15px;
    width: 100%;
    /* background: red; */
    text-align: left;
    font-size: 12px;
}

.profile-card-top-value{
    height: 35px;
    width: 100%;
    /* background: blue; */
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--dark-text);


}


.profile-content-bottom{
    height: 80px;
    width: calc(100% - 40px);
    /* background: red; */
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
}
.profile-card-bottom{
    height: 80px;
    /* width: 190px; */
    width: calc(25% - 15px);
    /* width: calc(25% - 100px); */
    border-radius: 20px;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.profile-card-bottom-icon{
    height: 50px;
    width: 50px;
    background:var(--launch-colour);
    border-radius: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-card-bottom-holder{
    height: 60px;
    width: calc(100% - 90px);
    /* background:var(--launch-colour); */
    display: flex;
    flex-direction: column;
}


.profile-card-bottom-holder-title{
    height: 15px;
    width: 100%;
    /* background: red; */
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    /* padding-top: 5px; */
    color: var(--text-colour);
}


.profile-card-bottom-holder-value{
    height: 37px;
    width: 100%;
    /* background: blue; */
    text-align: left;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--dark-text);
    text-indent: 5px;
}








.account-settings-holder-v2{
    height: auto;
    width: calc(100% - 90px);
    margin: auto;
    border-radius: 10px;
    background: white;
    border: 1px solid var(--launch-colour);
    padding: 20px;
    padding-bottom: 30px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
}

.account-settings-holder-v2-top{
    height: 100px;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* width: 250px; */
    /* background: red; */
}

.account-settings-holder-v2-top-icon{
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border:1px solid var(--launch-colour) ;
    overflow: hidden;
}
.account-settings-holder-v2-top-icon img{
    height: 100px;
    width: 100px;
    opacity: 0.5;
}

.account-settings-holder-v2-top-name{
    display: flex;
    flex-direction: column;
    width: auto;
}

.account-settings-holder-v2-top-name-value{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 28px;
    color: var(--text-colour);
}

.account-settings-holder-v2-top-name-tag{
    font-size: 10px;
    font-weight: 400;
    padding: 1.5px;
    font-family: 'DM Sans', sans-serif;
    background: #73a3f7;
    color:white;
    padding-left: 7.5px;
    padding-right: 7.5px;
    border-radius: 5px;
}
.account-settings-holder-v2-bottom {
    height: auto;
    width: 65%; /* Adjusted width to better fit grid layout */
    padding: 20px;
    margin: auto;
    /* margin-top: 20px; */
    display: grid;
    grid-template-columns: 1fr 1fr; /* Creates a 2-column grid */
    gap: 20px; /* Adds spacing between grid items */
    /* background: blue; */
}

.account-settings-bottom-title {
    margin-bottom: 2.5px;
    display: flex;
    align-items: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    color: var(--text-colour);
    text-indent: 15px;
}

.account-settings-bottom-value {
    background: var(--launch-colour2);
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-indent: 20px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    color: var(--text-colour);
}